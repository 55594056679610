import React, { useEffect, useRef, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import ButtonPrimary from "../UI/ButtonPrimary";
import FormBoxId from "../UI/FormBoxId";
import FormUserInfo from "../UI/FormUserInfo";
import Layout from "../UI/Layout";
import Spacer from "../UI/Spacer";
import Stack from "../UI/Stack";
import TextLink from "../UI/TextLink";
import { Helmet } from "react-helmet";
import ApiCaller, {
  DecryptedBoxIdResponse,
  UserOptionsResponse,
  UserResponse
} from "src/libs/ApiWrapper";
import { GenderType } from "src/libs/types/enums";
import useAppStateDispatch from "src/hooks/useAppStateDispatch";
import { useHandleException } from "src/hooks/useHandleException";

// ユーザ情報登録画面 - /registrations
const Registrations = (): JSX.Element => {
  const [gender, setGender] = useState<GenderType>(GenderType.MALE);
  const [birthday, setBirthday] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [boxId, setBoxId] = useState("");
  const boxIdRef = useRef<any>();

  const dispatch = useAppStateDispatch();
  const history = useHistory();
  const API = new ApiCaller();
  const handleException = useHandleException();
  const registrationResponse = (resp: UserResponse) => {
    localStorage.setItem("boxId", boxId);
    localStorage.setItem("encryptedBoxId", boxIdRef.current.encryptedBoxId());
    dispatch({
      type: "setUserDataAction",
      payload: {
        user: {
          id: resp.id,
          birthday: resp.birthday,
          gender: resp.gender,
          boxId: resp.boxId
        }
      }
    });
  };

  const updateUserDataResponse = (resp: UserOptionsResponse): void => {
    if (resp.status === 200 && Object.keys(resp.data).length) {
      if (resp.data.boxId) {
        localStorage.setItem("boxId", `${resp.data.boxId}`);
        localStorage.setItem("encryptedBoxId", `${resp.data.encryptedBoxId}`);
      } else {
        localStorage.removeItem("boxId");
        localStorage.removeItem("encryptedBoxId");
      }

      dispatch({
        type: "setUserDataAction",
        payload: {
          user: {
            ...resp.data
          }
        }
      });
    }
    history.push("/?registrations=true");
  };

  const handleSubmit = async () => {
    await API.registration(
      {
        boxId: Number(boxId) || undefined,
        gender,
        birthday: birthday || undefined
      },
      registrationResponse
    ).catch(err => {
      handleException(err);
    });
    await API.getUserOptions(updateUserDataResponse).catch(err =>
      handleException(err)
    );
    dispatch({
      type: "setAlertMessage",
      payload: {
        alertMessage: {
          type: "success",
          message: "ユーザーを登録しました"
        }
      }
    });
  };

  useEffect(() => {
    const encryptedBoxId = localStorage.getItem("boxId") || "";
    if (encryptedBoxId) {
      setBoxId(encryptedBoxId);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>ユーザー登録</title>
      </Helmet>
      <Layout>
        <Stack size={5}>
          <FormUserInfo
            gender={gender}
            setGender={setGender}
            setDisabled={setDisabled}
            setBirthday={setBirthday}
            signUp
          ></FormUserInfo>
          <FormBoxId
            ref={boxIdRef}
            boxId={boxId}
            setBoxId={setBoxId}
            setDisabled={setDisabled}
          ></FormBoxId>
          <div className="gl_text-center">
            <TextLink
              text="プライバシーポリシー"
              to={`/privacy_policy/profile`}
            ></TextLink>
            ｜<TextLink text="利用規約" to={`/terms_of_use/email`}></TextLink>
          </div>
          <Spacer size={2}></Spacer>
          <ButtonPrimary
            type="submit"
            text="登録"
            disabled={disabled}
            onClick={() => handleSubmit()}
          ></ButtonPrimary>
        </Stack>
      </Layout>
    </>
  );
};

export default Registrations;
