import React from "react";
import { useHistory } from "react-router-dom";
import ButtonPrimary from "../UI/ButtonPrimary";
import Card from "../UI/Card";
import FormCreditCard from "../UI/FormCreditCard";
import Header from "../UI/Header";
import Layout from "../UI/Layout";
import Stack from "../UI/Stack";

const CreditCardsRegistrations = (): JSX.Element => {
  const history = useHistory();
  const handleSubmit = () => {
    console.log("登録");
    history.goBack();
  };
  return (
    <>
      <Header backText="戻る"></Header>
      <Layout>
        <Stack size={5}>
          <Card>
            <FormCreditCard hiddenCheck></FormCreditCard>
          </Card>
          <ButtonPrimary
            type="submit"
            text="登録"
            color="primary"
            disabled={false}
            onClick={() => handleSubmit()}
          ></ButtonPrimary>
        </Stack>
      </Layout>
    </>
  );
};

export default CreditCardsRegistrations;
