import React from "react";
import ButtonPrimary from "../UI/ButtonPrimary";
import Card from "../UI/Card";
import Layout from "../UI/Layout";
import Stack from "../UI/Stack";
import { Helmet } from "react-helmet";
import { liff } from "src/libs/liff";
import history from "../History";

const WithdrawalComplete = (): JSX.Element => {
  const closeWindow = () => {
    if (liff.isInClient()) {
      liff.closeWindow();
    } else {
      history.push("/");
    }
  };
  return (
    <>
      <Helmet>
        <title>退会完了</title>
      </Helmet>
      <Layout>
        <Stack size={5}>
          <Card>
            <Stack size={4}>
              <div className="gl_text-h1 gl_text-center">
                退会の手続きが
                <br />
                完了しました
              </div>
              <div>
                オフィスおかんをご利用いただき、ありがとうございました。
                <br />
                またのご利用を、心よりお待ちしております。
              </div>
            </Stack>
          </Card>
          <div className="gl_text-center">
            <ButtonPrimary
              text="閉じる"
              color="primary"
              size="md"
              outlined
              onClick={() => closeWindow()}
            ></ButtonPrimary>
          </div>
        </Stack>
      </Layout>
    </>
  );
};

export default WithdrawalComplete;
