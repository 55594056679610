import history from "src/components/History"
import { System503Error, System5xxError } from "src/types/libs/Errors";

export const useHandleException = () => {
  const handleException = (err: any) => {
    if (err instanceof System503Error) {
      return history.push("/service_unavailable");
    } else if (err instanceof System5xxError) {
      return history.push("/server_error");
    }
    throw err;
  };

  return handleException;
};
