import React from "react";
import s from "./ButtonPrimary.module.scss";

interface Props {
  text: string;
  size?: "sm" | "md" | "lg";
  type?: "button" | "submit" | "reset";
  color?: "primary" | "danger" | "cancel";
  outlined?: boolean;
  disabled?: boolean;
  onClick?: Function;
}

const ButtonPrimary = (props: Props): JSX.Element => {
  return (
    <button
      type={props.type ?? "button"}
      className={`
        ${s.button}
        ${props.color ? s[`color_${props.color}`] : s.color_primary}
        ${props.outlined ? s.outlined : ""}
        ${props.disabled ? s.disabled : ""}
        ${props.size ? s[`size_${props.size}`] : s.size_lg}
      `}
      onClick={() => !props.disabled && props.onClick && props.onClick()}
    >
      {props.text}
    </button>
  );
};

export default ButtonPrimary;
