import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useHistory } from "react-router-dom";
import IconCartRedBrown from "../../assets/new-images/icon-md-cart-redbrown.svg";
import IconFoodChart from "../../assets/new-images/icon-md-foodChart.svg";
import IconWallet from "../../assets/new-images/icon-md-wallet.svg";
import imgPath from "../../assets/new-images/img-item-hamburg.jpg";
import {
  CardBrandType,
  CouponEffect,
  CouponTermsOfUse,
  GenderType,
  TransactionType,
  TransactionTypeConverter,
  TransactionTypeName
} from "../../libs/types/enums";
import ButtonIconCircle from "../UI/ButtonIconCircle";
import ButtonPrimary from "../UI/ButtonPrimary";
import Card from "../UI/Card";
import Divider from "../UI/Divider";
import FormBoxId from "../UI/FormBoxId";
import FormCreditCard from "../UI/FormCreditCard";
import Header from "../UI/Header";
import Layout from "../UI/Layout";
import ListItemCartMenu from "../UI/ListItemCartMenu";
import ListItemCreditCard from "../UI/ListItemCreditCard";
import ListNutrients from "../UI/ListNutrients";
import ListNutrientsSimple from "../UI/ListNutrientsSimple";
import Modal from "../UI/Modal";
import ModalInnerWarning from "../UI/ModalInnerWarning";
import Stack from "../UI/Stack";
import TextLink from "../UI/TextLink";
import TextNutrientsNote from "../UI/TextNutrientsNote";
import TextTitleIcon from "../UI/TextTitleIcon";
import s from "./Payment.module.scss";
import { Helmet } from "react-helmet";
import ListItemCreditCardNew from "../UI/ListItemCreditCardNew";
import { PayjpCheckout, usePayjpCheckout } from "easy-react-payjp";
import ApiCaller, {
  CardInfo,
  Coupon,
  NutrientIntakeValues,
  Order,
  OrdersResponse,
  UserCouponsResponse,
  UserResponse
} from "src/libs/ApiWrapper";
import useAppState from "src/hooks/useAppState";
import { CartItem } from "src/types/contexts/AppContext";
import useAppStateDispatch from "src/hooks/useAppStateDispatch";
import FormCards from "../UI/FormCard";
import dayjs from "dayjs";
import FormItemCheckbox from "../UI/FormItemCheckbox";
import { useHandleException } from "src/hooks/useHandleException";
import Loading from "../UI/Loading";

type Nutrient = {
  id: string;
  name: string;
  kcal?: number;
  totalKcal?: number;
};

const Payments = (): JSX.Element => {
  const history = useHistory();
  const [useCouponId, setUseCouponId] = useState<any>(null);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [boxId, setBoxId] = useState("");
  const API = new ApiCaller();
  const globalState = useAppState();
  const dispatch = useAppStateDispatch();
  const [latestOrderHistory, setLatestOrderHistory] = useState<Order>();
  const [userCoupons, setUserCoupons] = useState<Coupon[]>([]);
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const handleException = useHandleException();
  const userInfoRegistered =
    (globalState.user.gender === GenderType.MALE ||
      globalState.user.gender === GenderType.FAMALE) &&
    globalState.user.birthday;
  const age = globalState.user.birthday
    ? dayjs().diff(dayjs(globalState.user?.birthday), "year")
    : 30;

  const [paymentType, setPaymentType] = useState(TransactionType.PAYPAY);
  const transactionTypes = [
    {
      value: TransactionType.PAYPAY,
      name: TransactionTypeName[TransactionType.PAYPAY],
      img: require("../../assets/new-images/logo-pay-paypay.png")
    },
    {
      value: TransactionType.LINEPAY,
      name: TransactionTypeName[TransactionType.LINEPAY],
      img: require("../../assets/new-images/logo-pay-LINEpay.png")
    },
    {
      value: TransactionType.MERPAY,
      name: TransactionTypeName[TransactionType.MERPAY],
      img: require("../../assets/new-images/logo-pay-merpay.png")
    },
    {
      value: TransactionType.CREDIT_CARD,
      name: TransactionTypeName[TransactionType.CREDIT_CARD],
      img: require("../../assets/new-images/logo-pay-creditcard.png")
    }
  ];

  const [registerCardCheck, setRegisterCardCheck] = useState(true);

  const [creditCardId, setCreditCardId] = useState<string>();
  const [creditCards, setCreditCards] = useState<CardInfo[]>([]);
  const [nutrientIntakeValues, setNutrientIntakeValues] = useState<
    NutrientIntakeValues
  >();

  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(0);
  const [show, setShow] = useState(false);
  const [cardToken, setCardToken] = useState("");
  const boxIdRef = useRef<any>();
  const [showBoxForm, setShowBoxForm] = useState(false);

  const nutrients: Nutrient[] = useMemo(() => {
    const nutrientValues = cartItems.reduce(
      (acc, item) => {
        return {
          calorie: acc.calorie + item.calorie * item.quantity,
          protein: acc.protein + item.protein * item.quantity,
          lipid: acc.lipid + item.lipid * item.quantity,
          carbohydrates: acc.carbohydrates + item.carbohydrates * item.quantity,
          salt: acc.salt + item.salt * item.quantity
        };
      },
      { calorie: 0, protein: 0, lipid: 0, carbohydrates: 0, salt: 0 }
    );

    return [
      {
        id: "calorie",
        name: "エネルギー",
        kcal: nutrientValues?.calorie,
        totalKcal: nutrientIntakeValues?.calorie
      },
      {
        id: "protein",
        name: "たんぱく質",
        kcal: nutrientValues?.protein,
        totalKcal: nutrientIntakeValues?.protein
      },
      {
        id: "lipid",
        name: "脂質",
        kcal: nutrientValues?.lipid,
        totalKcal: nutrientIntakeValues?.lipid
      },
      {
        id: "carbohydrates",
        name: "炭水化物",
        kcal: nutrientValues?.carbohydrates,
        totalKcal: nutrientIntakeValues?.carbohydrates
      },
      {
        id: "salt",
        name: "食塩相当量",
        kcal: nutrientValues?.salt,
        totalKcal: nutrientIntakeValues?.salt
      }
    ];
  }, [cartItems, nutrientIntakeValues]);

  // PAYJP
  const checkoutProps = usePayjpCheckout({
    publicToken: `${process.env.REACT_APP_PAY_JP_PUBLIC_KEY}`,
    submitText: "新しいカードを追加する",
    text: "新しいカードを追加する",
    onTokenCreated: () => console.info("token created"),
    onTokenFailedToCreate: () => console.error("error"),
    partial: true
  });

  const userCouponResponse = (resp: UserCouponsResponse) => {
    setUserCoupons(resp.coupons);
    setShow(true);
  };

  const plus = (item: CartItem) => {
    dispatch({
      type: "addItemAction",
      payload: { item }
    });
  };

  const minus = (itemId: number) => {
    dispatch({
      type: "removeItemAction",
      payload: { itemId }
    });
  };

  const openDeleteItemModal = (itemId: number) => {
    setShowDeleteItemModal(true);
    setDeleteItemId(itemId);
  };

  const deleteItem = () => {
    dispatch({
      type: "deleteItemAction",
      payload: { itemId: deleteItemId }
    });
    setShowDeleteItemModal(false);
  };

  const orderCreditRequest = (resp: any, cardId: string) => {
    if (
      paymentType === TransactionType.CREDIT_CARD &&
      creditCardId === "NEW" &&
      !registerCardCheck
    ) {
      Promise.resolve().then(async () => {
        await API.deleteCard(cardId).catch(err => {
          handleException(err);
        });
      });
    }
    history.push("/payments/complete");
  };

  const orderRequest = (resp: any) => {
    history.push("/payments/complete");
  };

  const orderElepayReauest = (resp: any) => {
    Promise.resolve().then(async () => {
      const Elepay = require("elepay-js-sdk");
      const elepay = new Elepay(process.env.REACT_APP_ELEPAY_PUBLIC_KEY);
      setLoading(false);
      await elepay.handleCharge(resp.data).catch(function(err: any) {
        console.log(err);
      });
    });
  };

  const createPaymentCardId = async () => {
    if (creditCardId && creditCardId !== "NEW") {
      return creditCardId;
    } else if (creditCardId === "NEW" && cardToken) {
      const cardId = await API.postCreditCard({ cardToken: cardToken }).catch(
        err => {
          handleException(err);
        }
      );
      return cardId || "";
    } else {
      return "";
    }
  };

  const createPayment = () => {
    setLoading(true);
    Promise.resolve()
      .then(async () => {
        if (totalPrice - discount <= 0) {
          await API.postFreeOrder(
            {
              items: JSON.stringify(
                cartItems.map(item => {
                  return {
                    itemId: item.itemId,
                    quantity: item.quantity,
                    price: item.price
                  };
                })
              ),
              paymentType: paymentType,
              couponId: useCouponId
            },
            orderRequest
          ).catch(e => {
            handleException(e);
          });
        } else if (
          paymentType === TransactionType.CREDIT_CARD &&
          creditCardId
        ) {
          const cardId = await createPaymentCardId().catch();
          if (!cardId) {
            dispatch({
              type: "setAlertMessage",
              payload: {
                alertMessage: {
                  type: "error",
                  message: "クレジットカードの登録に失敗しました"
                }
              }
            });
            return;
          }
          await API.payByCreditCard(
            {
              items: JSON.stringify(
                cartItems.map(item => {
                  return {
                    itemId: item.itemId,
                    quantity: item.quantity,
                    price: item.price
                  };
                })
              ),
              paymentType: paymentType,
              cardToken: cardId,
              couponId: useCouponId
            },
            orderCreditRequest
          ).catch(e => {
            handleException(e);
          });
        } else {
          await API.postOrderRequest(
            {
              items: JSON.stringify(
                cartItems.map(item => {
                  return {
                    itemId: item.itemId,
                    quantity: item.quantity,
                    price: item.price
                  };
                })
              ),
              paymentType: paymentType,
              couponId: useCouponId
            },
            orderElepayReauest
          ).catch(e => {
            handleException(e);
          });
        }
      })
      .catch(() => {
        history.push("/error?errorType=internalServerError");
      });
  };

  const calcDiscount = (coupon: Coupon): number => {
    if (coupon.effect === CouponEffect.PRICE) {
      return coupon.effectValue1;
    } else if (coupon.effect === CouponEffect.PERCENT) {
      return (totalPrice * coupon.effectValue1) / 100;
    } else if (coupon.effect === CouponEffect.FREE) {
      const targetItemLength = cartItems
        .filter(item => item.price === coupon.effectValue1)
        .map(cartItem => cartItem.quantity)
        .reduce((r, o) => r + o, 0);
      return targetItemLength < coupon.effectValue2
        ? coupon.effectValue1 * targetItemLength
        : coupon.effectValue1 * coupon.effectValue2;
    }
    return 0;
  };

  const selectCoupon = (id: string, discount: number) => {
    if (useCouponId === id) {
      setUseCouponId("");
      setDiscount(0);
    } else {
      setUseCouponId(id);
      setDiscount(discount);
    }
  };

  const filterUsableCoupon: (Coupon & { discount: number })[] = useMemo(() => {
    const usableCoupons: (Coupon & { discount: number })[] = [];
    userCoupons.forEach(coupon => {
      if (coupon.termsOfUse === CouponTermsOfUse.COUNT) {
        const itemCount = cartItems.reduce((acc, item, index) => {
          return acc + item.quantity;
        }, 0);
        if (coupon.termsOfUseValue <= itemCount) {
          usableCoupons.push({ ...coupon, discount: calcDiscount(coupon) });
        }
      } else if (coupon.termsOfUse === CouponTermsOfUse.PRICE) {
        if (coupon.termsOfUseValue <= totalPrice) {
          usableCoupons.push({ ...coupon, discount: calcDiscount(coupon) });
        }
      } else {
        usableCoupons.push({ ...coupon, discount: calcDiscount(coupon) });
      }
    });
    const newUsableCoupon = usableCoupons.filter(coupon => coupon.discount > 0);
    if (!newUsableCoupon.map(coupon => coupon.id).includes(useCouponId)) {
      selectCoupon(useCouponId, 0);
    }
    return newUsableCoupon;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCoupons, cartItems, totalPrice]);

  const latestOrderHistoryResponse = (resp: OrdersResponse) => {
    setLatestOrderHistory(resp.data.orders[0]);
  };

  const nutrientIntakeValuesResponse = (resp: NutrientIntakeValues): void => {
    setNutrientIntakeValues(resp);
  };

  const updateResponse = (resp: UserResponse) => {
    if (resp.boxId) {
      localStorage.setItem("boxId", `${resp.boxId}`);
      localStorage.setItem("encryptedBoxId", boxIdRef.current.encryptedBoxId());
    } else {
      localStorage.removeItem("boxId");
      localStorage.removeItem("encryptedBoxId");
    }
    dispatch({
      type: "updateUserDataAction",
      payload: {
        user: {
          id: resp.id,
          birthday: resp.birthday,
          gender: resp.gender,
          boxId: resp.boxId
        }
      }
    });
  };

  const patchUser = useCallback(
    async (boxId: number) => {
      await API.patchUser(
        globalState.user.id,
        {
          boxId: boxId || undefined,
          gender: globalState.user.gender,
          birthday: globalState.user.birthday
        },
        updateResponse
      ).catch(err => {
        handleException(err);
      });
    },
    [boxId]
  );

  useEffect(() => {
    Promise.resolve().then(async () => {
      await API.getUserCoupons(userCouponResponse);
      await API.getLatestUserOrderHistory(latestOrderHistoryResponse);
      const cards = await API.getCards();
      await API.getNutrientIntakeValues(
        age,
        globalState.user?.gender,
        nutrientIntakeValuesResponse
      );
      setCreditCards(cards);
      setBoxId(globalState.user.boxId || "");
      setShowBoxForm(!globalState.user.boxId);
    });
  }, []);

  useEffect(() => {
    if (globalState.cart.length === 0) {
      history.push("/");
    }
    const sum = globalState.cart.reduce((acc, item, index) => {
      return acc + item.price * item.quantity;
    }, 0);
    setCartItems(globalState.cart);
    setTotalPrice(sum);
  }, [globalState.cart]);

  // 前回の支払い方法をデフォルトで選択
  useEffect(() => {
    if (!latestOrderHistory) {
      return;
    }
    const transactionType =
      TransactionTypeConverter[latestOrderHistory?.transactionType || 0];
    if (transactionType) {
      setPaymentType(transactionType);
    }
    setCreditCardId(latestOrderHistory.cardId);
  }, [latestOrderHistory]);

  return (
    <>
      <Helmet>
        <title>カートの確認</title>
      </Helmet>
      {loading && <Loading />}
      <Header backText="戻る" account></Header>
      <Layout>
        <Stack size={5}>
          {/* section - 冷蔵庫ID */}
          {showBoxForm && (
            <FormBoxId
              ref={boxIdRef}
              boxId={boxId}
              setBoxId={setBoxId}
              setDisabled={setDisabled}
              isPayment
              patchUser={patchUser}
            ></FormBoxId>
          )}
          {/* section - ご購入内容の確認 */}
          <Card>
            <Stack size={4}>
              {/* title */}
              <TextTitleIcon
                text="ご購入内容の確認"
                imgPath={IconCartRedBrown}
              ></TextTitleIcon>
              {/* CartList */}
              <div className={s.cardList}>
                <Stack size={2}>
                  {cartItems.map((item, i) => (
                    <ListItemCartMenu
                      key={item.itemId}
                      imgPath={item.thumbImg}
                      name={item.name}
                      price={item.price}
                      count={item.quantity}
                      last={i === cartItems.length - 1}
                      onClickMinus={() => minus(item.itemId)}
                      onClickPlus={() => plus(item)}
                      onClickDelete={() => openDeleteItemModal(item.itemId)}
                    ></ListItemCartMenu>
                  ))}
                </Stack>
              </div>
              {/* 小計 */}
              <Stack
                horizontal
                alignItems="center"
                justifyContent="space-between"
              >
                <div className="gl_text-sm gl_color-brown-300">小計</div>
                <div className="gl_text-sm gl_color-brown-500 gl_fontWeight-bold">
                  {`¥ ${totalPrice}`}
                </div>
              </Stack>
              <Divider></Divider>
              {/* ご利用可能クーポン */}
              {filterUsableCoupon.length > 0 && (
                <div className="gl_text-h3 gl_color-brown-300 gl_text-center">
                  ご利用可能クーポン
                </div>
              )}
              {/* クーポン一覧 */}
              {filterUsableCoupon.map((item, i) => (
                <>
                  <Stack key={i} horizontal alignItems="center" size={4}>
                    <div className="gl_flex-1 gl_color-brown-400 gl_truncate">
                      {item.name}
                    </div>
                    <div className="gl_text-h3 gl_fontWeight-bold ">
                      -¥ {item.discount}
                    </div>
                    <ButtonIconCircle
                      icon={useCouponId === item.id ? "check" : "none"}
                      active={useCouponId === item.id}
                      onClick={() => selectCoupon(item.id, item.discount)}
                    ></ButtonIconCircle>
                  </Stack>
                  <Divider></Divider>
                </>
              ))}
              {/* 合計 */}
              <Stack
                horizontal
                alignItems="center"
                justifyContent="space-between"
              >
                <div className="gl_text-h3 gl_color-redbrown-400">合計</div>
                <div className="gl_text-h1 gl_color-brown-500 gl_fontWeight-bold">
                  {totalPrice - discount > 0
                    ? `¥ ${totalPrice - discount}`
                    : "¥ 0"}
                </div>
              </Stack>
            </Stack>
          </Card>
          {/* section - お支払い方法 */}
          {totalPrice - discount > 0 && (
            <Card>
              <Stack size={4}>
                <TextTitleIcon
                  text="お支払い方法"
                  imgPath={IconWallet}
                ></TextTitleIcon>
                {transactionTypes.map((item, i) => (
                  <Stack key={i} horizontal alignItems="center">
                    <img
                      src={item.img}
                      alt={item.name}
                      width={"28"}
                      height={"28"}
                    />
                    <div className="gl_color-brown-400 gl_fontWeight-bold gl_flex-1">
                      {item.name}
                    </div>
                    <ButtonIconCircle
                      icon={paymentType === item.value ? "check" : "none"}
                      active={paymentType === item.value}
                      onClick={() => setPaymentType(item.value)}
                    ></ButtonIconCircle>
                  </Stack>
                ))}
                {/* クレジットカードがチェックされている場合表示 */}
                {paymentType === TransactionType.CREDIT_CARD && (
                  <Card backgroundColor>
                    <Stack size={4}>
                      {creditCards.map((creditCard, i) => (
                        <Stack key={i} size={4}>
                          <ListItemCreditCard
                            key={creditCard.id}
                            id={creditCard.id}
                            brand={creditCard.brand}
                            expYear={creditCard.expYear}
                            expMonth={creditCard.expMonth}
                            number={creditCard.last4}
                            checkActive={creditCardId === creditCard.id}
                            onClickCheck={() => setCreditCardId(creditCard.id)}
                          ></ListItemCreditCard>
                          <Divider></Divider>
                        </Stack>
                      ))}
                      <ListItemCreditCardNew
                        id="ListItemCreditCardNew"
                        checkActive={creditCardId === "NEW"}
                        onClickCheck={() => setCreditCardId("NEW")}
                      ></ListItemCreditCardNew>
                      <FormItemCheckbox
                        checked={registerCardCheck}
                        label="このクレジットカードを登録する"
                        onClick={() => setRegisterCardCheck(!registerCardCheck)}
                      ></FormItemCheckbox>
                      <div
                        className={
                          creditCardId === "NEW" ? "gl_block" : "gl_hidden"
                        }
                      >
                        <FormCards
                          setCards={setCreditCards}
                          setCardId={setCreditCardId}
                          setCardToken={setCardToken}
                          cart
                        />
                      </div>
                    </Stack>
                  </Card>
                )}
              </Stack>
            </Card>
          )}
          {/* section - カート内の合計栄養成分 */}
          <Card>
            <Stack size={4}>
              <TextTitleIcon
                text="カート内の合計栄養成分"
                imgPath={IconFoodChart}
              ></TextTitleIcon>
              {userInfoRegistered ? (
                <>
                  <ListNutrients nutrients={nutrients}></ListNutrients>
                  <TextNutrientsNote
                    age={Math.floor(age / 10) * 10}
                    gender={globalState.user.gender}
                  ></TextNutrientsNote>
                </>
              ) : (
                <ListNutrientsSimple
                  nutrients={nutrients}
                ></ListNutrientsSimple>
              )}
            </Stack>
          </Card>
          <ButtonPrimary
            text="この内容で購入"
            onClick={() => createPayment()}
            disabled={
              boxId === "" ||
              cartItems.length === 0 ||
              (paymentType === TransactionType.CREDIT_CARD &&
                (!creditCardId || (creditCardId === "NEW" && !cardToken)))
            }
          ></ButtonPrimary>
        </Stack>
      </Layout>
      {showDeleteItemModal && (
        <Modal close={setShowDeleteItemModal}>
          <ModalInnerWarning
            title={`カートから商品を\n削除してよろしいですか？`}
            submitButtonText="削除"
            onClickSubmit={() => deleteItem()}
            onClickCancel={() => setShowDeleteItemModal(false)}
          ></ModalInnerWarning>
        </Modal>
      )}
    </>
  );
};

export default Payments;
