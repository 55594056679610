import React from "react";
import s from "./TextTag.module.scss";

interface Props {
  text: string;
}

const TextTag = (props: Props): JSX.Element => {
  return <span className={`${s.base} gl_text-sm`}>{props.text}</span>;
};

export default TextTag;
