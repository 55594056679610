import React, { useEffect, useRef, useState } from "react";
import s from "./FormSelect.module.scss";
import Spacer from "./Spacer";

interface Props {
  list: string[];
  value: string;
  placeholder?: string;
  set: (value: string) => void;
}

const FormSelect = (props: Props): JSX.Element => {
  const [open, setOpen] = useState(false);
  const listRef = useRef<HTMLUListElement>(null);

  const handleClick = (value: string): void => {
    setOpen(false);
    props.set(value);
  };

  useEffect(() => {
    // Listの子要素からselectedのIndexを抽出
    const ListEl = document.querySelector("#List");
    if (!ListEl) return;
    const selectedIndex = Array.from(ListEl.querySelectorAll("div")).findIndex(
      item => {
        return item.className === `${s.item} ${s.item_selected}`;
      }
    );
    // selectedのelementを取得
    const selectedEl = ListEl.querySelector(`.${s.item_selected}`);
    if (!selectedEl) return;
    // List上部からselectedの位置の高さを算出
    const position = selectedEl?.clientHeight * selectedIndex;
    // selectedの位置を中央にするため、3Item分上にオフセット
    const offset = selectedEl?.clientHeight * 3;
    const position2 = position - offset;
    ListEl.scrollTo(0, position2);

    const handleClickToClose = (event: any) => {
      const element = listRef.current;
      if (!open || element?.contains(event.target)) return;
      setOpen(false);
    };
    window.addEventListener("click", handleClickToClose);
    return () => {
      window.removeEventListener("click", handleClickToClose);
    };
  }, [open, listRef]);

  return (
    <div className={s.wrap}>
      <div className={s.base} onClick={() => setOpen(!open)}>
        <div className={`${s.value} ${!props.value.length && s.placeholder}`}>
          {props.value.length ? props.value : props.placeholder}
        </div>
        <img
          id="FormSelectImg"
          className={`${s.img} ${open && s.img_rotate}`}
          src={require("../../assets/new-images/icon-md-angleBottom.svg")}
          alt=""
          width="20"
          height="20"
        />
      </div>
      {open && (
        <div className={s.listWrap}>
          <Spacer size={1}></Spacer>
          <div id="List" className={s.list}>
            {props.list.map((v, i) => (
              <div
                key={i}
                className={`${s.item} ${props.value === v && s.item_selected}`}
                onClick={() => handleClick(v)}
              >
                {v}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FormSelect;
