import HTTPClient from "../libs/HTTPClient";
import { HTTPClientResponse } from "../types/libs/HTTPClient";
import { LoginResponse } from "src/components/AppContextProvider";
import { CardBrandType, GenderType, TransactionType } from "./types/enums";
import {
  Box,
  ItemCategories,
  BoxItemResponse
} from "src/components/pages/Home";
import { System503Error, System5xxError } from "src/types/libs/Errors";

export interface UserOptionsResponse {
  status: number;
  statusText: string;
  headers: {
    status: number;
    statusText: string;
  };
  data: {
    id: number;
    boxId: number;
    birthday: string;
    gender: GenderType;
    encryptedBoxId: number;
  };
}

export interface OrderItem {
  itemId: number;
  name: string;
  price: number;
  subtotal: number;
  quantity: number;
  mainImg: string;
  thumbImg: string;
}

export type Item = {
  id: number;
  itemCategoryId: number;
  name: string;
  price: number;
  mainImg: string;
  thumbImg: string;
  description: string;
  order: number;
  enable: number;
  createdAt: string;
  updatedAt: string;
  itemCategoryName: string;
  calorie: number;
  protein: number;
  lipid: number;
  carbohydrates: number;
  salt: number;
  ingredient: string;
  foodAllergies: string;
};

export interface ItemResponse {
  data: {
    item: Item;
  };
}

export interface ItemsAttribute {
  box: Box | undefined;
  items: Item[];
  itemCategories: ItemCategories[];
  orderItemIds: number[];
}

export interface ItemsResponse {
  data: {
    attributes: ItemsAttribute;
  };
}

export interface NutrientIntakeValues {
  calorie: number;
  protein: number;
  lipid: number;
  carbohydrates: number;
  salt: number;
}

export interface Order {
  chargeId: string;
  date: string;
  totalPrice: number;
  transactionType: number;
  last4?: string;
  couponName: string;
  discount: number;
  items: OrderItem[];
  cardId: string | undefined;
}

export interface OrdersResponse {
  status: number;
  statusText: string;
  headers: {
    status: number;
    statusText: string;
  };
  data: {
    orders: Order[];
  };
}

interface RegistrationCompleteResponse {
  status: number;
  statusText: string;
  headers: {
    status: number;
    statusText: string;
  };
  data: {
    idToken: string;
    errors?: string;
    user: {
      birthday: string;
      boxId: number;
      email: string;
      gender: number;
      id: number;
      loginToken: string;
      occupation: number;
    };
  };
}

interface SignUpResponse {
  status: number;
  statusText: string;
  headers: {
    status: number;
    statusText: string;
  };
  data: { reason: string };
}

export interface DecryptedBoxIdResponse {
  isValidDigit: boolean;
  decryptedBoxId: number;
}

interface BoxItem {
  data: {
    data: BoxItemResponse;
  };
}

interface TokenCreationResponse {
  status: number;
  statusText: string;
  headers: {
    status: number;
    statusText: string;
  };
  data: {
    data: {
      token: string;
    };
  };
}

interface CreditCardResponse {
  order: {
    orderNo: string;
    status: string;
  };
}

interface FreeOrderResponse {
  order: {
    orderNo: string;
    status: string;
  };
}

export interface CardInfo {
  id: string;
  brand: CardBrandType;
  last4: string;
  expMonth: string;
  expYear: string;
}

export interface CardListResponse {
  cards: CardInfo[];
}

export interface UserResponse {
  id: number;
  lineUid: string;
  displayName: string;
  boxId?: number;
  birthday?: string;
  gender?: number;
  unsubscribedAt?: string;
  createdAt: string;
  updatedAt: string;
}

export interface Coupon {
  id: string;
  issueType: number;
  effect: number;
  effectValue1: number;
  effectValue2: number;
  termsOfUse: number;
  termsOfUseValue: number;
  name: string;
  note: string;
  startsAt: string;
  expiresAt: string;
  usableCount: number;
}

export interface UserCouponsResponse {
  coupons: Coupon[];
}

export interface RegisterResponse {
  data: { user: UserResponse };
}

export interface UpdateUserResponse {
  data: { user: UserResponse };
}

export interface CreateCardResponse {
  id: string;
  status?: number;
  data?: {
    errors: {
      title: string;
      detail: string;
      status: string;
    }[];
  };
}

class ApiCaller {
  private checkStatusCode = (statusCode: number) => {
    if (statusCode === 503) {
      throw new System503Error();
    } else if (statusCode >= 500) {
      throw new System5xxError();
    }
  };
  getCardInfo = async (userId: number): Promise<CardInfo | null> => {
    const cardInfo: CardInfo | null = await HTTPClient.get<CardInfo>(
      `/credit_card`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`
        }
      }
    ).then(res => {
      this.checkStatusCode(res.status);
      if (res.status === 404) {
        return null;
      }
      return res.data;
    });
    return cardInfo;
  };
  getCards = async (): Promise<CardInfo[]> => {
    const cardInfo: CardInfo[] | null = await HTTPClient.get<CardListResponse>(
      `/credit_cards`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`
        }
      }
    ).then(res => {
      this.checkStatusCode(res.status);
      if (res.status === 404) {
        return null;
      }
      return res.data.cards;
    });
    return cardInfo ? cardInfo : [];
  };
  deleteCard = async (cardId: string, response?: Function): Promise<void> => {
    await HTTPClient.delete(`/credit_card/${cardId}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`
      }
    }).then(res => {
      this.checkStatusCode(res.status);
      response && response(res);
    });
  };

  payByCreditCard = async (
    postParams: {
      items: string;
      cardToken: string;
      paymentType: TransactionType;
      couponId?: string;
    },
    response?: Function
  ): Promise<void> => {
    await HTTPClient.post<CreditCardResponse>(
      `/credit_card/order`,
      postParams,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`
        }
      }
    ).then(resp => {
      this.checkStatusCode(resp.status);
      if (response && resp.data?.order?.status !== "created") {
        throw new Error();
      }
      response && response(resp.data.order, postParams.cardToken);
    });
  };

  postFreeOrder = async (
    postParams: {
      items: string;
      paymentType: TransactionType;
      couponId?: string;
    },
    response?: Function
  ): Promise<void> => {
    await HTTPClient.post<FreeOrderResponse>(`/free_orders`, postParams, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`
      }
    }).then(resp => {
      this.checkStatusCode(resp.status);
      if (response && resp.data?.order?.status !== "created") {
        throw new Error();
      }
      response && response(resp.data.order);
    });
  };

  getBoxItem = async (
    boxId: number,
    userId?: number,
    response?: Function
  ): Promise<void> => {
    const getParams = userId ? { userId: userId } : {};
    await HTTPClient.get(`/boxes/${boxId}/items`, {
      query: getParams,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`
      }
    }).then((resp: HTTPClientResponse<BoxItem>) => {
      this.checkStatusCode(resp.status);
      response && response(resp.data.data);
    });
  };

  getItems = async (boxId: number, response?: Function): Promise<void> => {
    const url = boxId ? `/items?boxId=${boxId}` : "/items";
    await HTTPClient.get(url, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`
      }
    }).then((resp: HTTPClientResponse<ItemsResponse>) => {
      this.checkStatusCode(resp.status);
      response && response(resp.data.data.attributes);
    });
  };

  postUserOrderTokens = async (
    itemId: number,
    userId?: number,
    response?: Function
  ): Promise<void> => {
    await HTTPClient.post(
      `/users/order_tokens`,
      {
        itemId: itemId,
        userId: userId
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("idToken")}`
        }
      }
    ).then((resp: TokenCreationResponse) => {
      this.checkStatusCode(resp.status);
      response && response(resp.data.data, itemId);
    });
  };

  getUserOptions = async (response?: Function): Promise<void> => {
    await HTTPClient.get(`/users/options`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`
      }
    }).then((resp: UserOptionsResponse) => {
      this.checkStatusCode(resp.status);
      response && response(resp);
    });
  };

  getUserDetails = async (
    gender: number,
    birthday: string,
    occupation: number,
    userId: number,
    response?: VoidFunction
  ): Promise<void> => {
    await HTTPClient.patch(
      `/users/${userId}`,
      {
        boxId: localStorage.getItem("boxId"),
        gender: gender,
        birthday: birthday ? birthday : null,
        occupation: occupation
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("idToken")}`
        }
      }
    ).then(() => {
      response && response();
    });
  };

  getUserOrderHistory = async (
    year: number,
    month: number,
    response?: Function
  ): Promise<void> => {
    await HTTPClient.get(`/users/orders?month=${month}&year=${year}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`
      }
    }).then((resp: OrdersResponse) => {
      this.checkStatusCode(resp.status);
      response && response(resp);
    });
  };

  getLatestUserOrderHistory = async (response?: Function): Promise<void> => {
    await HTTPClient.get(`/users/orders?latestOnly=true`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`
      }
    }).then((resp: OrdersResponse) => {
      this.checkStatusCode(resp.status);
      response && response(resp);
    });
  };

  resetPassword = async (
    postParams: {
      encryptedBoxId: number;
      email: string;
      confirmationUrl: string;
    },
    response?: VoidFunction
  ): Promise<void> => {
    await HTTPClient.post("/auth/okanposQR/password/reset", postParams, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("idToken")}`
      }
    }).then(() => {
      response && response();
    });
  };

  passwordConfirm = async (
    postParams: {
      confirmationCode: string;
      userName: string;
      clientId: string;
      newPassword: string;
    },
    response?: VoidFunction
  ): Promise<void> => {
    await HTTPClient.post("/auth/okanposQR/password/confirm", postParams, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("idToken")}`
      }
    }).then(() => {
      response && response();
    });
  };

  getOrder = async (orderNo: string, response?: Function): Promise<void> => {
    await HTTPClient.get(`/orders`, {
      query: { chargeId: orderNo },
      headers: { Authorization: `Bearer ${localStorage.getItem("idToken")}` }
    }).then((resp: any) => {
      this.checkStatusCode(resp.status);
      response && response(resp);
    });
  };

  signIn = async (
    postParams: {
      email: string;
      password: string;
    },
    response?: Function,
    errorCatch?: VoidFunction
  ): Promise<void> => {
    await HTTPClient.post("/auth/okanposQR/sign_in", postParams, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("idToken")}`
      }
    })
      .then((resp: RegistrationCompleteResponse) => {
        this.checkStatusCode(resp.status);
        response && response(resp);
      })
      .catch(() => {
        errorCatch && errorCatch();
      });
  };

  snsLogin = async (privider: string, response?: Function): Promise<void> => {
    await HTTPClient.get(`/auth/okanposQR/authorize/${privider}`, {
      query: {
        redirectUri: window.location.origin + `/sns/login`
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("idToken")}` }
    }).then((resp: any) => {
      this.checkStatusCode(resp.status);
      response && response(resp);
    });
  };

  signUp = async (
    postParams: {
      encryptedBoxId: number;
      email: string;
      password: string;
      confirmationUrl: string;
    },
    response?: Function
  ): Promise<void> => {
    await HTTPClient.post("/auth/okanposQR/sign_up", postParams, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("idToken")}`
      }
    }).then((resp: SignUpResponse) => {
      response && response(resp);
    });
  };

  registration = async (
    postParams: {
      boxId?: number;
      birthday?: string;
      gender?: GenderType;
    },
    response?: Function
  ): Promise<void> => {
    await HTTPClient.post("/users", postParams, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`
      }
    }).then((resp: HTTPClientResponse<RegisterResponse>) => {
      this.checkStatusCode(resp.status);
      response && response(resp.data.data.user);
    });
  };

  getDetailItem = async (
    itemId: string,
    response?: Function
  ): Promise<void> => {
    await HTTPClient.get(`/items/${itemId}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`
      }
    }).then((resp: HTTPClientResponse<ItemResponse>) => {
      this.checkStatusCode(resp.status);
      response && response(resp.data);
    });
  };

  getNutrientIntakeValues = async (
    age: number,
    sex: number,
    response?: Function
  ): Promise<void> => {
    await HTTPClient.get(`/nutrient_intake_values?age=${age}&sex=${sex}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`
      }
    }).then((resp: HTTPClientResponse<NutrientIntakeValues>) => {
      this.checkStatusCode(resp.status);
      response && response(resp.data);
    });
  };

  login = async (token: string, response?: Function): Promise<string> => {
    const { accessToken } = await HTTPClient.post(
      "/login",
      { token },
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    ).then((resp: HTTPClientResponse<LoginResponse>) => {
      this.checkStatusCode(resp.status);
      return resp.data;
    });
    return accessToken;
  };
  decodeBoxId = async (
    boxId: number,
    response?: Function
  ): Promise<DecryptedBoxIdResponse> => {
    const data = await HTTPClient.get(`/boxes/${boxId}/decode`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`
      }
    }).then((resp: HTTPClientResponse<DecryptedBoxIdResponse>) => {
      this.checkStatusCode(resp.status);
      return resp.data;
    });
    return data;
  };
  getUserCoupons = async (response?: Function): Promise<void> => {
    await HTTPClient.get("/coupons", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`
      }
    }).then((resp: HTTPClientResponse<UserCouponsResponse>) => {
      this.checkStatusCode(resp.status);
      response && response(resp.data);
    });
  };
  deleteUser = async (userId: number, response?: Function): Promise<void> => {
    await HTTPClient.delete(`/users/${userId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`
      }
    }).then((resp: any) => {
      this.checkStatusCode(resp.status);
      response && response(resp);
    });
  };
  patchUser = async (
    userId: number,
    postParams: {
      boxId?: number;
      birthday?: string;
      gender?: GenderType;
    },
    response?: Function
  ): Promise<void> => {
    await HTTPClient.patch(`/users/${userId}`, postParams, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`
      }
    }).then((resp: HTTPClientResponse<UpdateUserResponse>) => {
      this.checkStatusCode(resp.status);
      response && response(resp.data.data.user);
    });
  };
  postCreditCard = async (
    postParams: {
      cardToken: string;
    },
    response?: Function
  ): Promise<string | null> => {
    const cardId = await HTTPClient.post("/credit_card", postParams, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`
      }
    }).then((resp: HTTPClientResponse<CreateCardResponse>) => {
      this.checkStatusCode(resp.status);
      return resp.data.id;
    });
    return cardId;
  };
  postOrderRequest = async (
    postParams: {
      items: string;
      paymentType: TransactionType;
      couponId?: string;
    },
    response?: Function
  ): Promise<void> => {
    await HTTPClient.post(
      "/orders/requests",
      { ...postParams, frontUrl: process.env.REACT_APP_FRONT_BASE_URL },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`
        }
      }
    ).then(resp => {
      this.checkStatusCode(resp.status);
      if (response && Number(resp.data?.data?.status) >= 400) {
        throw new Error();
      }
      response && response(resp.data.data);
    });
  };
  createUserCoupon = async (
    postParams: {
      couponId?: string;
    },
    response?: Function
  ): Promise<void> => {
    await HTTPClient.post("/users/coupons", postParams, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`
      }
    }).then((resp: HTTPClientResponse<UserCouponsResponse>) => {
      this.checkStatusCode(resp.status);
      response && response(resp.data);
    });
  };
}

export default ApiCaller;
