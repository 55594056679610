import React from "react";
import { CardBrandType, CardBrandTypeName } from "src/libs/types/enums";

interface Props {
  brand: CardBrandType | "ADD";
}

const ImgCardBrand = (props: Props): JSX.Element => {
  const cardBrandTypeName = CardBrandTypeName;
  return (
    <>
      {props.brand === "ADD" ? (
        <img
          src={require(`../../assets/new-images/logo-card-add.png`)}
          alt={"add"}
          width="36"
        />
      ) : (
        <img
          src={require(`../../assets/new-images/logo-card-${
            cardBrandTypeName[props.brand]
          }.png`)}
          alt={cardBrandTypeName[props.brand]}
          width="36"
        />
      )}
    </>
  );
};

export default ImgCardBrand;
