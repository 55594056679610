import React, { FC, useEffect, useState } from "react";
import "../../assets/stylesheets/application.scss";
import history from "../History";
import Button from "./ButtonWide";
import assets from "../asset";

interface GenericErrorProps {
  queryString: Record<string, any>;
}

const GenericErrorScreen: FC<GenericErrorProps> = (
  props: GenericErrorProps
) => {
  const [mounted, setMounted] = useState(false);
  const { queryString } = props;
  const goToTopScreen = (): void => {
    history.push("/");
  };

  const errorText = (): string => {
    let text = "";
    switch (queryString.errorType) {
      case "notFound":
        text = "お探しのページは見つかりませんでした";
        break;
      case "internalServerError":
        text = "エラーが発生しました。\n時間をおいて再度お試し下さい。";
        break;
      case "undefinedBoxId":
        text =
          "対象の冷蔵庫が見つかりません。\nQRが正しいか再度ご確認ください。";
        break;
      default:
        text = "お探しのページは見つかりませんでした";
    }
    return text;
  };

  return (
    <div className="h-100">
      <div className="vertical-flow align-text-center mt-120">
        <img className="mt-48" alt="" src={assets.xInCircle} />
        <div className="text-size-14px nsj-medium mt-14 white-space-pre-wrap">
          {errorText()}
        </div>
        <Button
          btnAction={(): void => goToTopScreen()}
          btnText="＜ TOPへ戻る"
        />
      </div>
    </div>
  );
};
export default GenericErrorScreen;
