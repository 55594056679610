import React from "react";
import TextCaption from "./TextCaption";

const Footer = (): JSX.Element => {
  return (
    <div style={{ height: "32px" }}>
      <div className="gl_text-center">
        <TextCaption
          text={`${
            new Date().getFullYear() === 2022
              ? `©2022`
              : `©2022-${new Date().getFullYear()}`
          } OKAN Inc.`}
          size="xs"
          color="300"
          weight="600"
        ></TextCaption>
      </div>
    </div>
  );
};

export default Footer;
