import React from "react";
import Header from "../UI/Header";
import { Helmet } from "react-helmet";

const PrivacyPolicy = (): JSX.Element => {
  return (
    <>
      <Helmet>
        <title>プライバシーポリシー</title>
      </Helmet>
      <Header backText="戻る"></Header>
      <iframe
        id="inline-frame"
        title="privacy-policy"
        src="https://okan.co.jp/privacy-policy/"
        className="gl_iframe"
      ></iframe>
    </>
  );
};

export default PrivacyPolicy;
