import React, { ReactNode } from "react";
import s from "./Card.module.scss";

interface Props {
  backgroundColor?: boolean;
  backgroundImg?: boolean;
  paddingX?: boolean;
  children: ReactNode;
}

const Card = (props: Props): JSX.Element => {
  let className = s.card;
  if (props.backgroundImg) className = s.card_bgImg;
  if (props.paddingX) className = s.card_x;
  if (props.backgroundColor) className = s.card_color;
  return <div className={className}>{props.children}</div>;
};

export default Card;
