import React from "react";
import { CardBrandType } from "src/libs/types/enums";
import ImgCardBrand from "./ImgCardBrand";
import Stack from "./Stack";

const IconListCreditCard = (): JSX.Element => {
  return (
    <Stack horizontal size={2}>
      <ImgCardBrand brand={CardBrandType.VISA}></ImgCardBrand>
      <ImgCardBrand brand={CardBrandType.JCB}></ImgCardBrand>
      <ImgCardBrand brand={CardBrandType.MASTER}></ImgCardBrand>
      <ImgCardBrand brand={CardBrandType.DINERS}></ImgCardBrand>
      <ImgCardBrand brand={CardBrandType.AMEX}></ImgCardBrand>
    </Stack>
  );
};

export default IconListCreditCard;
