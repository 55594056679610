import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import FormSearchItems from "../UI/FormSearchItems";
import Layout from "../UI/Layout";
import Stack from "../UI/Stack";
// import TabItem from "../UI/TabItem";
import { Helmet } from "react-helmet";
import { Link as Scroll } from "react-scroll";
import useAppState from "src/hooks/useAppState";
import useAppStateDispatch from "src/hooks/useAppStateDispatch";
import { useHandleException } from "src/hooks/useHandleException";
import ApiCaller, {
  Item,
  ItemsAttribute,
  UserCouponsResponse
} from "src/libs/ApiWrapper";
import ButtonPrimary from "../UI/ButtonPrimary";
import CardGetCoupon from "../UI/CardGetCoupon";
import Footer from "../UI/Footer";
import FooterCart from "../UI/FooterCart";
import ListItemMenu from "../UI/ListItemMenu";
import Modal from "../UI/Modal";
import Spacer from "../UI/Spacer";
import s from "./Home.module.scss";

export interface CartItem {
  itemId: number;
  quantity: number;
  price: number;
  name: string;
  mainImg: string;
}

export type BoxItemResponse = {
  attributes: {
    box: Box;
    favorites: Favorites[];
    itemCategories: ItemCategories[];
    items: Items[];
    orders: Order[];
  };
};

export type Order = {
  id: number;
  chargeId: string;
  date: string;
  status: number;
  companyName: string;
  transactionType: number;
  items: OrderItem[];
};

export type OrderItem = {
  itemId: number;
  price: number;
  itemCategoryName: string;
  quantity: number;
  name: string;
  mainImg: string;
  thumbImg: string;
};

export type Favorites = {
  id: number;
  userId: number;
  itemId: number;
};

export type Items = {
  id: number;
  itemCategoryId: number;
  name: string;
  price: number;
  mainImg: string;
  thumbImg: string;
};

export type ItemCategories = {
  id: number;
  name: string;
  categoryImg: string;
  order: number;
};

export type Box = {
  boxId: number;
  containerBoxCount: number;
  contractId: number;
  cutterCount: number;
  name: string;
  floor: number;
  floorName: string;
  isDisplayOkanpay: number;
  isExists: number;
  isItoen: number;
  isUseCollectionBox: number;
  onePlateBoxCount: number;
  registrationStatus: number;
  riceBoxCount: number;
  storageType: number;
};

const Home = (): JSX.Element => {
  const [mounted, setMounted] = useState(false);
  const history = useHistory();
  // const { queryString } = props;
  const globalState = useAppState();
  const dispatch = useAppStateDispatch();
  const [itemCategories, setItemCategories] = useState<ItemCategories[]>([]);
  const [box, setBox] = useState<Box>();
  const [items, setItems] = useState<Item[]>([]);
  const [searchWord, setSearchWord] = useState<string>();
  const [orderItemIds, setOrderItemIds] = useState<number[]>([]);
  const searchItemInputRef = useRef<{ resetValue: () => void }>(null);
  // const [emptyCard, setEmptyCard] = useState<JSX.Element[]>([]);
  const [totalCost, setTotalCost] = useState<number>(0);
  const [couponName, setCouponName] = useState("");
  const API = new ApiCaller();
  const handleException = useHandleException();

  const itemsResponse = (resp: ItemsAttribute): void => {
    if (resp) {
      setOrderItemIds(resp.orderItemIds);
      setItemCategories(resp.itemCategories);
      setItems(resp.items);
      setBox(resp.box);
      setMounted(true);
    }
  };

  useEffect(() => {
    const boxId: number | null = Number(localStorage.getItem("boxId"));
    // const user: User | undefined = JSON.parse(
    //   localStorage.getItem("user") || "undefined"
    // );
    Promise.resolve().then(async () => {
      await API.getItems(boxId, itemsResponse).catch(err => {
        handleException(err);
      });
    });
  }, []);

  useEffect(() => {
    const getTotalCost = (): number => {
      const sum = globalState.cart.map((item: CartItem): number => {
        return item.price * item.quantity;
      });
      return sum.length > 0
        ? sum.reduce((a: number, b: number): number => {
            return a + b;
          })
        : 0;
    };
    const sumArray = (array: number[]): number => {
      let sum = 0;
      for (let i = 0, len = array.length; i < len; i++) {
        sum += array[i];
      }
      return sum;
    };
    const totalItemCount = sumArray(
      globalState.cart.map((item: CartItem) => {
        return item.quantity;
      })
    );

    setTotalCost(getTotalCost());
    setCartItemCount(totalItemCount);
  }, [globalState]);

  const addItem = async (item: Item): Promise<void> => {
    dispatch({
      type: "addItemAction",
      payload: {
        item: {
          itemId: item.id,
          price: item.price,
          name: item.name,
          mainImg: item.mainImg,
          thumbImg: item.thumbImg,
          calorie: item.calorie,
          protein: item.protein,
          lipid: item.lipid,
          carbohydrates: item.carbohydrates,
          salt: item.salt,
          ingredient: item.ingredient
        }
      }
    });
  };

  // =====new
  const searchItems: Item[] = useMemo(() => {
    if (searchWord) {
      return items.filter(item => item.name.indexOf(searchWord) > -1);
    }
    return items;
  }, [items, searchWord]);

  const menus = useMemo(() => {
    const repurchaseMenus = [];
    if (orderItemIds.length > 0 && !searchWord) {
      repurchaseMenus.push({
        id: "Repurchase",
        name: "再購入",
        dishes: searchItems.filter(item => orderItemIds.includes(item.id))
      });
    }
    const itemMenus = itemCategories.map(category => {
      return {
        id: `itemCategory${category.id}`,
        name: category.name,
        dishes: searchItems.filter(item => item.itemCategoryId === category.id)
      };
    });
    return repurchaseMenus.concat(itemMenus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted, searchItems, searchWord]);

  const [showModal, setShowModal] = useState(false);
  const [cartItemCount, setCartItemCount] = useState(0);

  // query取得
  const search = useLocation().search;
  const query2 = new URLSearchParams(search);
  const isRegistered = query2.get("registrations") === "true";
  const couponId = query2.get("couponId");

  const addToCart = async (item: Item) => {
    await addItem(item);
    setCartItemCount(cartItemCount + 1);
    const cartItemCounterEl = document.getElementById("cartItemCounter");
    cartItemCounterEl?.classList.add(s.anime_shakeTop);
    setTimeout(() => {
      cartItemCounterEl?.classList.remove(s.anime_shakeTop);
    }, 800);
  };

  const ref1 = useRef<HTMLDivElement>(null);

  const onSetActive = () => {
    const active = document.querySelector(`.${s.active}`);
    const activeRect = active?.getBoundingClientRect();
    if (ref1.current && activeRect) {
      ref1.current.scrollLeft = activeRect.x;
    }
  };

  const [scrollPoint, setScrollPoint] = useState(0);
  const scrollPointRef = useRef(0);
  scrollPointRef.current = scrollPoint;
  const [lastPoint, setLastPoint] = useState(0);
  const lastPointRef = useRef(0);
  lastPointRef.current = lastPoint;

  const createCouponResponse = (resp: UserCouponsResponse) => {
    if (resp.coupons?.[0]) {
      setCouponName(resp?.coupons[0].name);
      setShowModal(true);
    }
  };

  // 画面スクロール位置を判定
  const getScrollVerticalPosition = ({
    scrollTop,
    scrollHeight,
    clientHeight
  }: {
    scrollTop: number;
    scrollHeight: number;
    clientHeight: number;
  }): string => {
    const isScrollTop = scrollTop <= 1;
    const isScrollBottom = scrollTop >= scrollHeight - clientHeight - 1;
    if (isScrollTop) {
      return "top";
    } else if (isScrollBottom) {
      return "bottom";
    } else {
      return "scrolling";
    }
  };

  const handleTouchMove = (event: any) => {
    event.preventDefault();
  };

  useEffect(() => {
    // スクロール時header表示非表示用イベントリスナー設定
    const headerEl = document.getElementById("header");
    document.addEventListener("scroll", function() {
      const {
        scrollTop,
        scrollHeight,
        clientHeight
      } = document.scrollingElement!;

      const scrollPosition = getScrollVerticalPosition({
        scrollTop,
        scrollHeight,
        clientHeight
      });

      setScrollPoint(scrollTop);
      // topとbottomに到達したときスクロール禁止にする
      if (scrollPosition === "top" || scrollPosition === "bottom") {
        //スクロール禁止
        document.addEventListener("touchmove", handleTouchMove, {
          passive: false
        });
        //スクロール復帰
        document.removeEventListener("touchmove", handleTouchMove, false);
        return;
      }
      if (
        scrollPointRef.current > lastPointRef.current &&
        scrollPosition === "scrolling"
      ) {
        headerEl?.classList.add(s.fixed_hide);
      } else {
        headerEl?.classList.remove(s.fixed_hide);
      }
      setLastPoint(scrollTop);
    });

    if (isRegistered) {
      Promise.resolve().then(async () => {
        await API.createUserCoupon({}, createCouponResponse).catch(err => {
          handleException(err);
        });
      });
    }

    if (couponId) {
      Promise.resolve().then(async () => {
        await API.createUserCoupon({ couponId }, createCouponResponse).catch(
          err => {
            handleException(err);
          }
        );
      });
    }
  }, []);

  const onSearch = (word: string): void => {
    setSearchWord(word);
  };

  if (!globalState.user.id) {
    return <Redirect to={`/registrations`} />;
  }

  return (
    <>
      {/* ====== new */}
      <Helmet>
        <title>商品一覧</title>
      </Helmet>
      <div id="page">
        {/* Header */}
        <div id="header" className={s.header}>
          {/* search */}
          <div style={{ padding: "10px", background: "white" }}>
            <Stack horizontal alignItems="center" size={2}>
              <div className="gl_flex-1">
                <FormSearchItems
                  menuItems={items}
                  onSearch={onSearch}
                  ref={searchItemInputRef}
                ></FormSearchItems>
              </div>
              <Link to="/profiles/menu">
                <img
                  src={require("../../assets/new-images/icon-lg-accountMenu.svg")}
                  alt="プロフィール画面"
                />
              </Link>
            </Stack>
          </div>
          {/* tabs */}
          <div className={s.tab} ref={ref1}>
            {menus.map(menu => {
              return (
                <Scroll
                  key={menu.id}
                  activeClass={s.active}
                  to={menu.id}
                  spy={true}
                  offset={-120}
                  duration={0}
                  className={s.tabItem}
                  onSetActive={() => onSetActive()}
                >
                  {menu.name}
                </Scroll>
              );
            })}
          </div>
        </div>
        {/* items */}
        <Layout noFooter>
          <Spacer size={22}></Spacer>
          {mounted && menus.every(m => m.dishes.length === 0) ? (
            <div>
              <Spacer size={4}></Spacer>
              <Stack size={5} alignItems="center">
                <img
                  src={require("../../assets/new-images/icon-xl-search.svg")}
                  alt=""
                  width={84}
                  height={84}
                />
                <div className="gl_color-brown-400 gl_text-center">
                  お探しのキーワードで
                  <br />
                  商品が見つかりませんでした
                </div>
                <ButtonPrimary
                  text="検索をリセット"
                  size="md"
                  color="cancel"
                  outlined
                  onClick={() => {
                    searchItemInputRef.current?.resetValue();
                    setSearchWord("");
                  }}
                ></ButtonPrimary>
              </Stack>
              <Spacer size={5}></Spacer>
            </div>
          ) : (
            <>
              {menus.map(menu => {
                return (
                  <div key={menu.id} id={menu.id}>
                    {menu.dishes.map((dish, i) => (
                      <div key={i}>
                        <Spacer size={4}></Spacer>
                        <ListItemMenu
                          id={dish.id}
                          imgPath={dish.thumbImg}
                          name={dish.name}
                          price={dish.price}
                          onClick={() => addToCart(dish)}
                        ></ListItemMenu>
                      </div>
                    ))}
                  </div>
                );
              })}
            </>
          )}
          <Spacer size={4}></Spacer>
          <Footer></Footer>
          <Spacer size={118}></Spacer>
        </Layout>
        {/* footer */}
        <FooterCart count={cartItemCount} price={totalCost} />
      </div>
      {/* dialog */}
      {showModal && (
        <Modal
          close={setShowModal}
          closeButton
          title={
            <Stack horizontal alignItems="center" justifyContent="center">
              <img
                src={require("../../assets/new-images/icon-lg-success.svg")}
                alt=""
              />
              <div className="gl_text-h3 gl_color-green-500">
                クーポンを取得しました
              </div>
            </Stack>
          }
        >
          <CardGetCoupon name={couponName}></CardGetCoupon>
        </Modal>
      )}
    </>
  );
};
export default Home;
