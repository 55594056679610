import dayjs from "dayjs";
import { useState, useEffect } from "react";
import ApiCaller, { UserOptionsResponse } from "src/libs/ApiWrapper";
import { liff, liffInit } from "src/libs/liff";
import logger from "src/libs/Logger";
import useAppStateDispatch from "./useAppStateDispatch";
import * as jwt from "jsonwebtoken";
import useAppState from "./useAppState";
import { useHandleException } from "./useHandleException";

export interface LiffInitState {
  ready: boolean;
}

export const useLiffInit = (): LiffInitState => {
  const dispatch = useAppStateDispatch();
  const globalState = useAppState();
  const [ready, setReady] = useState(false);
  const API = new ApiCaller();
  const handleException = useHandleException();

  const isNearExpiration = () => {
    if (!globalState.sessionToken) {
      return false;
    }

    logger.info("認証有効期限: ", globalState.sessionToken.expiredAt);
    const timeAfterOneHour = dayjs().add(1, "hour");
    return timeAfterOneHour.isAfter(
      dayjs.unix(globalState.sessionToken.expiredAt)
    );
  };

  const updateUserDataResponse = (resp: UserOptionsResponse): void => {
    if (resp.status === 200 && Object.keys(resp.data).length) {
      localStorage.setItem(
        "boxId",
        resp.data.boxId ? `${resp.data.boxId}` : ""
      );
      localStorage.setItem(
        "encryptedBoxId",
        resp.data.encryptedBoxId ? `${resp.data.encryptedBoxId}` : ""
      );
      dispatch({
        type: "setUserDataAction",
        payload: {
          user: {
            ...resp.data
          }
        }
      });
    } else if (resp.status === 404 || !Object.keys(resp.data).length) {
      logger.info("未登録");
    }
    setReady(true);
  };

  useEffect(() => {
    if (["/terms"].includes(window.location.pathname)) {
      setReady(true);
      logger.info("静的ファイルのパス", { path: window.location.pathname });
      return;
    }

    if (globalState.login && !isNearExpiration()) {
      logger.info("ログイン済み");
      setReady(true)
      return;
    }
    Promise.resolve().then(async () => {
      await liffInit(process.env.REACT_APP_FE_MAIN_LIFF_ID!);

      // ログイン
      logger.info("ログイン");
      const accessToken = await API.login(liff.getIDToken()).catch(err => {
        handleException(err);
        return "";
      });
      if (!accessToken) {
        setReady(true)
        return;
      }
      const decodedToken = jwt.decode(accessToken) as any;
      sessionStorage.setItem("accessToken", accessToken);
      dispatch({
        type: "loginAction"
      });

      dispatch({
        type: "setSessionToken",
        payload: {
          sessionToken: {
            lineId: decodedToken.sub,
            channelId: liff.getDecodedIDToken()?.aud!,
            issuedAt: decodedToken.iss,
            expiredAt: decodedToken.exp
          }
        }
      });

      await API.getUserOptions(updateUserDataResponse).catch(err => {
        handleException(err);
      });

      return;
    });
    // eslint-disable-next-line
  }, []);

  return {
    ready
  };
};
