import React from "react";

interface Props {
  size: number;
  horizontal?: boolean;
}

const Spacer = (props: Props): JSX.Element => {
  return (
    <div
      id="Spacer"
      style={
        props.horizontal
          ? {
              width: `${props.size / 2.5}rem`,
              height: "auto",
              display: "inline-block",
              flexShrink: 0,
            }
          : { width: "auto", height: `${props.size / 2.5}rem`, flexShrink: 0 }
      }
    />
  );
};

export default Spacer;
