import React from "react";
import s from "./CardAboutBoxId.module.scss";
import Spacer from "./Spacer";
import Stack from "./Stack";
import TextCaption from "./TextCaption";
import TextTitle from "./TextTitle";

const CardAboutBoxId = (): JSX.Element => {
  return (
    <div className={s.card}>
      <Stack size={4}>
        <TextTitle
          center
          text="冷蔵庫IDとは"
          size="lg"
          color="brown"
        ></TextTitle>
        <img
          src={require("../../assets/new-images/img-about-box-id.svg")}
          alt="冷蔵庫IDとは"
        />
        <div className="">
          <TextCaption
            text="※実際のシールの位置はイラストと異なる場合があります"
            size="xs"
            color="400"
          ></TextCaption>
          <Spacer size={2}></Spacer>
          <TextCaption
            text="※冷蔵庫IDが正しく読み取れない場合は、御社の運用担当者様
          までお問い合わせください"
            size="xs"
            color="400"
          ></TextCaption>
        </div>
      </Stack>
    </div>
  );
};

export default CardAboutBoxId;
