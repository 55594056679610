import React from "react";
import { GenderType, GenderTypeName } from "src/libs/types/enums";

interface Props {
  age: number;
  gender: GenderType;
}

const TextNutrientsNote = (props: Props): JSX.Element => {
  const genderText = GenderTypeName[props.gender];
  return (
    <div className="gl_text-xs gl_color-brown-400">
      {`※商品1食あたりの栄養成分が1日の食事摂取量基準に占める割合［${props.age}代${genderText}］`}
    </div>
  );
};

export default TextNutrientsNote;
