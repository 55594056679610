import { PayjpV2, PayJpV2Element, usePayjpV2 } from "easy-react-payjp";
import React, { useState } from "react";
import FormItemCheckbox from "./FormItemCheckbox";
import IconListCreditCard from "./IconListCreditCard";
import Stack from "./Stack";

interface Props {
  hiddenCheck?: boolean;
}

const FormCreditCard = (props: Props): JSX.Element => {
  const [registerCardCheck, setRegisterCardCheck] = useState(true);

  // PAYJPスタイルドキュメント
  // https://pay.jp/docs/payjs#style
  const style = {
    base: {
      backgroundColor: "#fff",
      color: "#665d5a",
      caretColor: "#fc6c26",
      fontSize: "14px",
      "::placeholder": {
        color: "#ada3a0",
      },
    },
    invalid: {
      caretColor: "#fa377a",
    },
  };

  const v2Props = usePayjpV2({
    publicToken: `${process.env.REACT_APP_PAY_JP_PUBLIC_KEY}`,
    buttonText: "",
    onTokenCreated: () => console.log("createToken"),
    onNumberFormInputChange: () => console.log("form changed"),
  });

  return (
    <Stack size={4}>
      <script src="https://js.pay.jp/v2/pay.js"></script>
      <IconListCreditCard></IconListCreditCard>
      {/* TODO: PAYJP実装すること */}
      <PayjpV2 {...v2Props}>
        <PayJpV2Element name="cardNumber" id="cardNumber" style={style} />
        <PayJpV2Element name="cardExpiry" id="cardExpiry" style={style} />
        <PayJpV2Element name="cardCvc" id="cardCvc" style={style} />
      </PayjpV2>
      {!props.hiddenCheck && (
        <FormItemCheckbox
          checked={registerCardCheck}
          label="このクレジットカードを登録する"
          onClick={() => setRegisterCardCheck(!registerCardCheck)}
        ></FormItemCheckbox>
      )}
    </Stack>
  );
};

export default FormCreditCard;
