import React from "react";
import Card from "./Card";
import { Allergy, Nutrient } from "./CardItemMenu";
import s from "./CardItemMenuSimple.module.scss";
import Divider from "./Divider";
import ItemAllergySel from "./ItemAllergySel";
import ListNutrientsSimple from "./ListNutrientsSimple";
import Stack from "./Stack";
import TextLink from "./TextLink";

interface Props {
  name: string;
  description: string;
  nutrients: Nutrient[];
  ingredient: string;
  allergySels: Allergy[];
}

const CardItemMenuSimple = (props: Props): JSX.Element => {
  return (
    <Card>
      <Stack size={4}>
        <div className="gl_text-h1 gl_color-redbrown-400">{props.name}</div>
        <div className="gl_text-sm gl_color-brown-400">{props.description}</div>
        <div className={s.title}>
          <div className={`${s.title_text} gl_text-sm`}>栄養成分</div>
        </div>
        <ListNutrientsSimple nutrients={props.nutrients}></ListNutrientsSimple>
        <div className="gl_text-xs gl_color-brown-400">
          ※性別と生年月日を登録すると、あなたにぴったりの1日分の栄養素をご案内します。登録は
          <TextLink
            text="こちら"
            to="/profiles/accounts/edit"
            size="xs"
          ></TextLink>
        </div>
        <Divider></Divider>
        <div className="gl_text-sm gl_color-redbrown-400 gl_text-center gl_fontWeight-bold">
          原材料
        </div>
        <div className="gl_text-sm gl_color-brown-400">{props.ingredient}</div>
        <Divider></Divider>
        <div className="gl_text-sm gl_color-redbrown-400 gl_text-center gl_fontWeight-bold">
          アレルギー
        </div>
        <div className={s.grid}>
          {props.allergySels.map((item, i) => (
            <ItemAllergySel
              key={i}
              name={item.name}
              active={item.active}
            ></ItemAllergySel>
          ))}
        </div>
      </Stack>
    </Card>
  );
};

export default CardItemMenuSimple;
