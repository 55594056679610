import React from "react";
import Card from "../UI/Card";
import Header from "../UI/Header";
import Layout from "../UI/Layout";
import { Helmet } from "react-helmet";

const SpecifiedCommercialTransactionAct = (): JSX.Element => {
  return (
    <>
      <Helmet>
        <title>特定商取引法に基づく表示</title>
      </Helmet>
      <Header backText="戻る"></Header>
      <Layout>
        <Card>
          <div className="gl_document">
            <h1>特定商取引法に基づく表示</h1>
            <hr />
            <p>
              「特定商取引に関する法律」第11条（通信販売についての広告）に基づき、以下に明示いたします。
            </p>
            <h2>事業者</h2>
            <p>株式会社OKAN</p>
            <h3>代表者</h3>
            <p>沢木恵太</p>
            <h3>所在地</h3>
            <p>東京都豊島区西池袋二丁目41番8号 I・O・B6階</p>
            <h3>ホームページ</h3>
            <p>
              <a
                href="https://office.okan.jp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://office.okan.jp/
              </a>
            </p>
            <h3>連絡先</h3>
            <p>050-1746-8088</p>
            <h2>販売価格</h2>
            <p>
              当社アプリ上で表示されている商品代金（表示価格）で販売しています。ただし、当該表示価格は商品仕入れの状況などにより常に変動するため、お客様のご注文確定時のサイト上の表示価格と当社請求時のサイト上の表示価格が一致しない場合は、原則としてお客様ご注文確定時点の表示価格が商品代金となります。
              手数料等は商品代金に含みます。
            </p>
            <h2>商品代金以外の必要料金</h2>
            <p>商品代金以外の必要料金はありません。商品代金のみとなります。</p>
            <h2>代金の支払時期</h2>
            <p>商品購入時に課金します。</p>
            <h2>商品引渡時期</h2>
            <p>当社アプリで決済完了した時に引渡します。</p>
            <h2>お支払方法</h2>
            <ul>
              <li>クレジットカード決済</li>
              <li>PayPay決済</li>
              <li>LINE Pay決済</li>
              <li>メルペイ決済</li>
            </ul>
            <h2>返品・交換について</h2>
            <p>
              お客様都合での返品は原則お受けしておりませんので、予めご了承ください。
              <br />
              商品の品質には万全の注意を払っておりますが、万一、商品に不備があった場合は、お支払い後5日以内に当社カスタマーサポート
              (お問い合わせ窓口) までお申し出ください。
            </p>

            {/* <h1>H1見出し</h1>
            <hr />
            <h2>H2見出し</h2>
            <p>
              本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。
              <a
                href="https://okan.co.jp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                リンクテキスト
              </a>
              。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。
            </p>
            <ul>
              <li>リストテキスト</li>
              <li>リストテキスト</li>
            </ul>
            <ol>
              <li>番号付きリストテキスト</li>
              <li>番号付きリストテキスト</li>
            </ol>
            <h3>H3見出し</h3>
            <p>
              本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。
            </p>
            <hr />
            <h2>H2見出し</h2>
            <p>
              本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。
              <a
                href="https://okan.co.jp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                リンクテキスト
              </a>
              。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。
            </p>
            <ul>
              <li>リストテキスト</li>
              <li>リストテキスト</li>
            </ul>
            <ol>
              <li>番号付きリストテキスト</li>
              <li>番号付きリストテキスト</li>
            </ol>
            <h3>H3見出し</h3>
            <p>
              本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。
            </p> */}
          </div>
        </Card>
      </Layout>
    </>
  );
};

export default SpecifiedCommercialTransactionAct;
