import React, { useEffect, useState } from "react";
import CardOrderHistory from "../UI/CardOrderHistory";
import FormSelect from "../UI/FormSelect";
import Header from "../UI/Header";
import Layout from "../UI/Layout";
import Spacer from "../UI/Spacer";
import Stack from "../UI/Stack";
import { Helmet } from "react-helmet";
import ApiCaller, { Order, OrdersResponse } from "src/libs/ApiWrapper";
import dayjs from "dayjs";
import {
  TransactionTypeName,
  TransactionTypeNumber
} from "src/libs/types/enums";
import { useHistory } from "react-router-dom";
import { System503Error, System5xxError } from "src/types/libs/Errors";
import { useHandleException } from "src/hooks/useHandleException";
import Loading from '../UI/Loading';

const OrderHistories = (): JSX.Element => {
  const months: string[] = [];
  const [month, setMonth] = useState(dayjs().format("YYYY年MM月"));
  const [orderHistories, setOrderHistories] = useState<Order[]>([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [loading, setLoading] = useState(true);
  const API = new ApiCaller();
  const history = useHistory();
  const handleException = useHandleException();

  let m = dayjs("2022-08-01");
  const now = dayjs();
  while (m.isBefore(now)) {
    months.push(m.format("YYYY年MM月"));
    m = m.add(1, "M");
  }

  const orderHistoriesResponse = (resp: OrdersResponse) => {
    setOrderHistories(resp.data.orders);
    let total = 0;
    resp.data.orders.map(order => (total = total + (order.totalPrice || 0)));
    setTotalPrice(total);
  };

  const formatPaymentMethod = (order: Order): string => {
    if (order.transactionType === TransactionTypeNumber.CREDIT_CARD) {
      return `${TransactionTypeName[order.transactionType]}(下4桁：${
        order.last4
      })`;
    } else {
      return TransactionTypeName[order.transactionType];
    }
  };

  useEffect(() => {
    setLoading(true);
    const target = month.match(/\d+/g);
    Promise.resolve().then(async () => {
      await API.getUserOrderHistory(
        Number(target![0]),
        Number(target![1]),
        orderHistoriesResponse
      ).catch(err => {
        handleException(err);
      });
      setLoading(false);
    });
  }, [month]);

  return (
    <>
      <Helmet>
        <title>購入履歴</title>
      </Helmet>
      <Header backText="戻る"></Header>
      {loading && <Loading />}
      <Layout>
        <Stack horizontal justifyContent="space-between" alignItems="center">
          <FormSelect list={months} value={month} set={setMonth}></FormSelect>
          <div>
            <span className="gl_fontWeight-bold">月の合計：</span>
            <span className="gl_text-h1">¥ {totalPrice.toLocaleString()}</span>
          </div>
        </Stack>
        <Spacer size={5}></Spacer>
        <Stack size={5}>
          {orderHistories.map((order, i) => (
            <CardOrderHistory
              key={i}
              createdAt={order.date}
              orderItems={order.items.map(item => {
                return {
                  imgPath: item.thumbImg,
                  name: item.name,
                  price: item.price,
                  count: item.quantity
                };
              })}
              coupon={
                order.couponName
                  ? {
                      name: order.couponName,
                      discount: order.discount
                    }
                  : undefined
              }
              totalPrice={order.totalPrice}
              //Todo 決済情報取得
              orderId={order.chargeId}
              paymentMethod={formatPaymentMethod(order)}
            ></CardOrderHistory>
          ))}
        </Stack>
      </Layout>
    </>
  );
};

export default OrderHistories;
