import React from "react";
import { useHistory } from "react-router-dom";
import ButtonPrimary from "./ButtonPrimary";
import s from "./FooterCart.module.scss";
import Spacer from "./Spacer";
import Stack from "./Stack";

interface Props {
  count?: number;
  price: number;
  isAddItem?: boolean;
  addItem?: Function;
}

const FooterCart = (props: Props): JSX.Element => {
  const history = useHistory();

  const addItem = () => {
    if (props.addItem) {
      props.addItem();
    }
    history.push("/");
  };

  return (
    <div className={s.footer}>
      <Stack horizontal justifyContent="space-between" alignItems="center">
        <Stack horizontal alignItems="center">
          <div className={s.icon}>
            <img
              src={require("../../assets/new-images/icon-lg-cart.svg")}
              alt=""
            />
            {!props.isAddItem && (
              <div id="cartItemCounter" className={s.icon_count}>
                {props.count}
              </div>
            )}
          </div>
          <Spacer horizontal size={5}></Spacer>
          <div className="gl_fontWeight-bold">
            ¥ <span className="gl_text-h1">{props.price.toLocaleString()}</span>
          </div>
        </Stack>
        <div>
          {props.isAddItem ? (
            <ButtonPrimary
              size="md"
              text="カートに追加"
              outlined
              onClick={() => addItem()}
            ></ButtonPrimary>
          ) : (
            <ButtonPrimary
              size="md"
              text="カートを確認"
              disabled={!props.count}
              onClick={() => history.push("/payments")}
            ></ButtonPrimary>
          )}
        </div>
      </Stack>
    </div>
  );
};

export default FooterCart;
