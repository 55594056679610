import React from "react";
import Stack from "./Stack";
import s from "./ListNutrients.module.scss";

interface Props {
  // TODO: any型を変更すること
  nutrients: any[];
}

const ListNutrients = (props: Props): JSX.Element => {
  return (
    <Stack size={4}>
      {props.nutrients.map((item, i) => (
        <div key={i}>
          <Stack
            horizontal
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <div className="gl_text-xs gl_color-redbrown-400 gl_fontWeight-bold">
              {item.name}
            </div>
            <Stack
              horizontal
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <div className="gl_text-h3 gl_color-redbrown-400">
                {item.kcal?.toFixed(1)}
              </div>
              <div className="gl_text-xs gl_color-brown-300 gl_fontWeight-bold">
                / {item.totalKcal?.toFixed(1)}{" "}
                {item.id === "calorie" ? " kcal" : " g"}
              </div>
            </Stack>
          </Stack>
          <div className={s.bar}>
            <div className={s.bar_all}></div>
            <div
              className={s.bar_in}
              style={{
                width: `${Math.min((item.kcal / item.totalKcal) * 100, 100)}%`
              }}
            ></div>
          </div>
        </div>
      ))}
    </Stack>
  );
};

export default ListNutrients;
