import React from "react";
import s from "./CardWarning.module.scss";

interface Props {
  text: string;
}

const CardWarning = (props: Props): JSX.Element => {
  return <div className={s.base}>{props.text}</div>;
};

export default CardWarning;
