import React, { useEffect } from "react";
import useAppStateDispatch from "src/hooks/useAppStateDispatch";
import s from "./Alert.module.scss";

interface Props {
  type: "success" | "error";
  text: string;
}

const Alert = (props: Props): JSX.Element => {
  const dispatch = useAppStateDispatch();
  useEffect(() => {
    const f = async () => {
      const alertEl = document.getElementById("Alert");
      alertEl!.classList.add(s.anime_fadeIn);
      await new Promise(r => setTimeout(r, 5000));
      alertEl!.classList.remove(s.anime_fadeIn);
      alertEl!.classList.add(s.anime_fadeOut);
      alertEl?.addEventListener("animationend", () => {
        dispatch({
          type: "clearAlertMessage"
        });
      });
      await new Promise(r => setTimeout(r, 1000));
    };
    f();
  }, []);
  return (
    <div id="Alert" className={`${s[`base_${props.type}`]}`}>
      <img
        src={require(`../../assets/new-images/icon-lg-${props.type}.svg`)}
        alt=""
        width="36"
        height="36"
      />
      <div className={s.text}>{props.text}</div>
    </div>
  );
};

export default Alert;
