import dayjs from "dayjs";
import React from "react";
import AppContext from "../contexts/AppContext";
import {
  AppState,
  AppStateReducer,
  ItemObject
} from "../types/contexts/AppContext";

const initialState: AppState = {
  cart: [],
  backDrop: false,
  modalBackDrop: false,
  selectedScreen: "main",
  selectedPaymentOption: "",
  userOrderToken: "",
  favourites: [],
  user: {
    id: 0,
    birthday: "",
    gender: 0,
    boxId: 0,
    encryptedBoxId: 0
  },
  showAlert: false,
  alertMessage: {
    type: "success",
    message: "",
    time: ""
  },
  login: false,
  currentCategory: 1,
  sessionToken: undefined
};

interface User {
  id: number;
  birthday: string;
  gender: number;
  boxId: number;
  encryptedBoxId?: number;
}

export interface LoginResponse {
  accessToken: string;
}

const addItemToCart = (
  item: ItemObject,
  globalState: AppState,
  updatedItemIndex: number
): AppState => {
  const updatedCart = [...globalState.cart];
  if (updatedItemIndex < 0) {
    updatedCart.push({
      itemId: item.itemId,
      quantity: 1,
      price: item.price,
      name: item.name,
      mainImg: item.mainImg,
      thumbImg: item.thumbImg,
      calorie: item.calorie,
      protein: item.protein,
      lipid: item.lipid,
      carbohydrates: item.carbohydrates,
      salt: item.salt,
      ingredient: item.ingredient
    });
  } else {
    const updatedItem = {
      ...updatedCart[updatedItemIndex]
    };
    updatedItem.quantity++;
    updatedCart[updatedItemIndex] = updatedItem;
  }
  localStorage.setItem("cartData", JSON.stringify(updatedCart));
  return { ...globalState, cart: updatedCart };
};
const removeItemFromCart = (
  itemId: number,
  globalState: AppState,
  updatedItemIndex: number
): AppState => {
  const updatedCart = [...globalState.cart];
  const updatedItem = {
    ...updatedCart[updatedItemIndex]
  };
  updatedItem.quantity--;
  if (updatedItem.quantity <= 0) {
    updatedCart.splice(updatedItemIndex, 1);
  } else {
    updatedCart[updatedItemIndex] = updatedItem;
  }
  localStorage.setItem("cartData", JSON.stringify(updatedCart));
  if (updatedCart.length > 0) {
    return { ...globalState, cart: updatedCart };
  } else {
    return { ...globalState, cart: updatedCart, backDrop: false };
  }
};

const deleteItemFromCart = (
  itemId: number,
  globalState: AppState
): AppState => {
  const updatedCart = globalState.cart.filter(item => item.itemId !== itemId);

  localStorage.setItem("cartData", JSON.stringify(updatedCart));

  if (updatedCart.length > 0) {
    return { ...globalState, cart: updatedCart };
  } else {
    return { ...globalState, cart: updatedCart, backDrop: false };
  }
};

const toggleFavourite = (
  itemId: number,
  globalState: AppState,
  updatedItemIndex: number
): AppState => {
  const favourites = [...globalState.favourites];
  if (updatedItemIndex < 0) {
    favourites.push({
      itemId: itemId
    });
  } else {
    favourites.splice(updatedItemIndex, 1);
  }
  return { ...globalState, favourites: favourites };
};

const getItemIndex = (itemId: number, globalState: AppState): number => {
  const updatedCart = [...globalState.cart];
  return updatedCart.findIndex(
    (cartItem): boolean => cartItem.itemId === itemId
  );
};

const getFavouriteIndex = (itemId: number, globalState: AppState): number => {
  const updateFavourites = [...globalState.favourites];
  return updateFavourites.findIndex(
    (favouriteItem): boolean => favouriteItem.itemId === itemId
  );
};

const setUser = (userData: User) => {
  const user = {
    id: userData.id,
    birthday: userData.birthday,
    gender: userData.gender,
    boxId: userData.boxId
  };
  return user;
};

const updateUser = (userData: User, globalState: AppState) => {
  const user = {
    id: userData.id,
    birthday: userData.birthday,
    gender: userData.gender,
    boxId: userData.boxId || globalState.user.boxId
  };

  return user;
};

const setFavourites = (
  favouriteList: any[],
  globalState: AppState
): AppState => {
  const favourites = [...globalState.favourites];
  favouriteList.forEach((item: any) => {
    favourites.push({ itemId: item.itemId });
  });
  return { ...globalState, favourites: favourites };
};

const clearCart = (): [] => {
  localStorage.setItem("cartData", JSON.stringify([]));
  return [];
};

const reducer: AppStateReducer = (prevState, action: any): AppState => {
  switch (action.type) {
    case "addUserOrderTokenAction":
      return { ...prevState, userOrderToken: action.payload.userOrderToken };
    case "clearUserOrderTokenAction":
      return { ...prevState, userOrderToken: "" };
    case "addItemAction":
      return addItemToCart(
        action.payload.item,
        prevState,
        getItemIndex(action.payload.item.itemId, prevState)
      );
    case "removeItemAction":
      return removeItemFromCart(
        action.payload.itemId,
        prevState,
        getItemIndex(action.payload.itemId, prevState)
      );
    case "deleteItemAction":
      return deleteItemFromCart(action.payload.itemId, prevState);
    case "setCartAction":
      return { ...prevState, cart: action.payload.cart };
    case "clearCartAction":
      return { ...prevState, cart: clearCart(), backDrop: false };
    case "toggleBackDropAction":
      return { ...prevState, backDrop: !prevState.backDrop };
    case "toggleModalBackDropAction":
      return { ...prevState, modalBackDrop: !prevState.modalBackDrop };
    case "setScreenAction":
      return { ...prevState, selectedScreen: action.payload.screen };
    case "loginAction":
      return { ...prevState, login: true };
    case "logoutAction":
      return { ...prevState, login: false };
    case "toggleFavouriteAction":
      return toggleFavourite(
        action.payload.itemId,
        prevState,
        getFavouriteIndex(action.payload.itemId, prevState)
      );
    case "updateUserDataAction":
      return {
        ...prevState,
        user: updateUser(action.payload.user, prevState)
      };
    case "setUserDataAction":
      return { ...prevState, user: setUser(action.payload.user), login: true };
    case "setFavouritesAction":
      return setFavourites(action.payload.favourites, prevState);
    case "selectedPaymentOptionAction":
      return {
        ...prevState,
        selectedPaymentOption: action.payload.selectedPaymentOption
      };
    case "setCurrentCategory":
      return {
        ...prevState,
        currentCategory: action.payload.currentCategory
      };
    case "setSessionToken":
      return {
        ...prevState,
        sessionToken: action.payload.sessionToken
      };
    case "setShowAlert":
      return {
        ...prevState,
        showAlert: action.payload.showAlert
      };
    case "setAlertMessage":
      return {
        ...prevState,
        alertMessage: {
          ...action.payload.alertMessage,
          time: dayjs().format()
        },
        showAlert: false
      };
    case "clearAlertMessage":
      return {
        ...prevState,
        alertMessage: { type: "", message: "", time: dayjs().format() },
        showAlert: false
      };
    default:
      return { ...prevState };
  }
};
const AppContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer<AppStateReducer>(
    reducer,
    initialState
  );

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
export default AppContextProvider;
