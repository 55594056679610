import React, { Ref, useEffect, useImperativeHandle, useState } from "react";
import { useHandleException } from "src/hooks/useHandleException";
import useAppState from "src/hooks/useAppState";
import ApiCaller, { DecryptedBoxIdResponse } from "src/libs/ApiWrapper";
import { liff } from "src/libs/liff";
import Card from "../UI/Card";
import FormInputText from "../UI/FormInputText";
import Label from "../UI/Label";
import Modal from "../UI/Modal";
import Stack from "../UI/Stack";
import TextCaption from "../UI/TextCaption";
import TextLink from "../UI/TextLink";
import CardAboutBoxId from "./CardAboutBoxId";
import s from "./FormBoxId.module.scss";

interface Props {
  boxId: string;
  setBoxId: (val: string) => void;
  setDisabled: (val: boolean) => void;
  isPayment?: boolean;
  patchUser?: Function;
  errorMessage?: string;
}

const FormBoxId = React.forwardRef(
  (props: Props, ref): JSX.Element => {
    const [showModal, setShowModal] = useState(false);
    const [encryptedBoxId, setEncryptedBoxId] = useState("");
    const [isError, setIsError] = useState(false);
    const API = new ApiCaller();
    const globalState = useAppState();
    const handleException = useHandleException();

    useImperativeHandle(ref, () => ({
      encryptedBoxId(): string {
        return encryptedBoxId;
      }
    }));

    const openQrReader = () => {
      liff.scanCodeV2().then(async (result: any) => {
        // QRリーダー実行後、何も読まずに閉じた場合
        if (!result.value) {
          return;
        }

        let url: URL;
        try {
          url = new URL(result.value);
        } catch {
          // 冷蔵庫に貼ってあるもの以外のQRを読み込んだ場合
          setIsError(true);
          return;
        }

        const query2 = new URLSearchParams(url.search);
        const encryptedBoxId = query2.get("id");

        // 読み込んだQRコードにboxIdのパラメータがなかった場合
        if (!Number(encryptedBoxId)) {
          setIsError(true);
          return;
        }

        const resp = await API.decodeBoxId(Number(encryptedBoxId)).catch(err =>
          handleException(err)
        );
        if (!resp || !resp?.isValidDigit) {
          setIsError(true);
        } else {
          setIsError(false);
          setEncryptedBoxId(encryptedBoxId!);
          props.setBoxId(`${resp.decryptedBoxId}`);
          if (props.isPayment && props.patchUser) {
            props.patchUser(resp.decryptedBoxId);
          }
        }
      });
    };

    const resetBoxId = () => {
      setEncryptedBoxId("");
      props.setBoxId("");
    };

    useEffect(() => {
      if (localStorage.getItem("encryptedBoxId") === "invalid") {
        setIsError(true);
      } else {
        setEncryptedBoxId(localStorage.getItem("encryptedBoxId") || "");
      }
    }, []);

    return (
      <>
        <Card>
          <Stack size={2}>
            <Stack horizontal justifyContent="space-between">
              <div>
                <Label text="冷蔵庫ID"></Label>
                {props.isPayment && (
                  <span className="gl_color-pink-500 gl_text-sm gl_fontWeight-bold">
                    ＊必須
                  </span>
                )}
              </div>
              <TextLink
                text="冷蔵庫IDとは"
                onClick={() => setShowModal(true)}
              ></TextLink>
            </Stack>
            {encryptedBoxId ? (
              <div
                className={s.input}
                onClick={() => {
                  resetBoxId();
                }}
              >
                <div>{encryptedBoxId}</div>
                <img
                  src={require("../../assets/new-images/icon-md-close.svg")}
                  alt=""
                />
              </div>
            ) : (
              <>
                <div
                  className={s.input}
                  onClick={() => {
                    openQrReader();
                  }}
                >
                  <div className="gl_color-brown-400">QRコードを読み込む</div>

                  <img
                    src={require("../../assets/new-images/icon-md-qrcode.svg")}
                    alt=""
                  />
                </div>
                {isError && (
                  <div className="gl_text-sm gl_color-pink-500">
                    冷蔵庫IDが正しくありません。詳しくは「冷蔵庫IDとは」をご確認ください。
                  </div>
                )}
              </>
            )}
            {props.isPayment && (
              <TextCaption text="※冷蔵庫IDの登録がないと決済ができません"></TextCaption>
            )}
          </Stack>
        </Card>
        {showModal && (
          <Modal close={setShowModal} closeButton>
            <CardAboutBoxId></CardAboutBoxId>
          </Modal>
        )}
      </>
    );
  }
);

export default FormBoxId;
