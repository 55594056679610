import React, { ReactNode } from "react";
import Footer from "./Footer";
import s from "./Layout.module.scss";
import Spacer from "./Spacer";

interface Props {
  children: ReactNode;
  noFooter?: boolean;
}

const Layout = (props: Props): JSX.Element => {
  return (
    <div className={s.bg}>
      <div className={s.bg_inner}>
        {props.children}
        {!props.noFooter && (
          <>
            <Spacer size={5}></Spacer>
            <Footer></Footer>
          </>
        )}
      </div>
    </div>
  );
};

export default Layout;
