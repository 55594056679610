import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ButtonPrimary from "../UI/ButtonPrimary";
import Card from "../UI/Card";
import Layout from "../UI/Layout";
import Stack from "../UI/Stack";
import TextLink from "../UI/TextLink";
import TextTitleIcon from "../UI/TextTitleIcon";
import IconCartRedBrown from "../../assets/new-images/icon-md-cart-redbrown.svg";
import Divider from "../UI/Divider";
import Spacer from "../UI/Spacer";
import { Helmet } from "react-helmet";
import useAppStateDispatch from 'src/hooks/useAppStateDispatch';

const PaymentsComplete = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppStateDispatch();
  const search = useLocation().search;
  const query2 = new URLSearchParams(search);
  const paymentStatus = query2.get("status");

  useEffect(() => {
    if (paymentStatus !== "cancelled") {
      dispatch({ type: "clearCartAction" });
    } else {
      history.push("/payments");
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>ご購入完了</title>
      </Helmet>
      <Layout>
        <div className="gl_text-center">
          <img
            src={require("../../assets/new-images/bg-thankyou.svg")}
            alt="thankyou"
          />
        </div>
        <Stack size={5}>
          <Card>
            <Stack size={4}>
              <TextTitleIcon
                text="ご購入ありがとうございました！"
                imgPath={IconCartRedBrown}
              ></TextTitleIcon>
              <Divider></Divider>
              <div className="gl_text-h3 gl_color-brown-300 gl_text-center">
                電子レンジでの温め時間
              </div>
              <div className="gl_mx-auto">
                <img
                  src={require("../../assets/new-images/img-warming-time.svg")}
                  alt="電子レンジでの温め時間"
                  width="100%"
                />
                <Spacer size={2}></Spacer>
                <div className="gl_align-middle">
                  <span className="gl_text-sm gl_color-brown-400 gl_align-middle">
                    メニュー表の
                  </span>
                  {/* <span> */}
                  <img
                    src={require("../../assets/new-images/icon-md-onmark.svg")}
                    alt="温"
                    className="gl_align-middle"
                    style={{
                      marginLeft: "4px",
                      marginRight: "4px",
                    }}
                  />
                  {/* </span> */}
                  <span className="gl_text-sm gl_color-brown-400 gl_align-middle">
                    商品は温めてお召し上がりください
                  </span>
                </div>
              </div>
              <div className="gl_text-xs gl_color-brown-400">
                ※ご利用の電子レンジや商品の種類によって時間が前後することがあります。適宜ご調整ください。
              </div>
            </Stack>
          </Card>
          <ButtonPrimary
            text="商品一覧に戻る"
            outlined
            onClick={() => history.push("/")}
          ></ButtonPrimary>
          <div className="gl_text-center">
            <TextLink
              text="購入履歴"
              onClick={() => history.push("/profiles/order-histories")}
            ></TextLink>
          </div>
        </Stack>
      </Layout>
    </>
  );
};

export default PaymentsComplete;
