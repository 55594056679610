export enum FollowStatusType {
  FOLLOW = 0,
  BLOCK = 1
}

export const FollowStatusTypeName: Record<number, string> = {
  [FollowStatusType.FOLLOW]: "フォロー中",
  [FollowStatusType.BLOCK]: "ブロック中"
};

export enum GenderType {
  MALE = 1,
  FAMALE = 2,
  OTHER = 3,
  noanswer = 4
}

export const GenderTypeName: Record<number, string> = {
  [GenderType.MALE]: "男性",
  [GenderType.FAMALE]: "女性",
  [GenderType.OTHER]: "その他",
  [GenderType.noanswer]: "無回答"
};

export enum TransactionType {
  PAYPAY = "paypay",
  MERPAY = "merpay",
  LINEPAY = "linepay",
  CREDIT_CARD = "credit_card"
}

export enum TransactionTypeNumber {
  PAYPAY = 1,
  MERPAY = 2,
  LINEPAY = 3,
  CREDIT_CARD = 4
}

export const TransactionTypeConverter: Record<number, TransactionType> = {
  [TransactionTypeNumber.PAYPAY]: TransactionType.PAYPAY,
  [TransactionTypeNumber.MERPAY]: TransactionType.MERPAY,
  [TransactionTypeNumber.LINEPAY]: TransactionType.LINEPAY,
  [TransactionTypeNumber.CREDIT_CARD]: TransactionType.CREDIT_CARD
};

export const TransactionTypeName: Record<string, string> = {
  [TransactionType.PAYPAY]: "PayPay",
  [TransactionType.MERPAY]: "メルペイ",
  [TransactionType.LINEPAY]: "LINE Pay",
  [TransactionType.CREDIT_CARD]: "クレジットカード",
  [TransactionTypeNumber.PAYPAY]: "PayPay",
  [TransactionTypeNumber.MERPAY]: "メルペイ",
  [TransactionTypeNumber.LINEPAY]: "LINE Pay",
  [TransactionTypeNumber.CREDIT_CARD]: "クレジットカード"
};

// 発行方法
export enum MethodOfIssue {
  AUTO = 1,
  MANUAL = 2
}

export const MethodOfIssueName: Record<number, string> = {
  [MethodOfIssue.AUTO]: "自動発行",
  [MethodOfIssue.MANUAL]: "手動発行"
};

// 効果
export enum CouponEffect {
  PRICE = 1,
  PERCENT = 2,
  FREE = 3
}

export const CouponEffectName: Record<number, string> = {
  [CouponEffect.PRICE]: "自動発行",
  [CouponEffect.PERCENT]: "手動発行",
  [CouponEffect.FREE]: "手動発行"
};

// 利用条件
export enum CouponTermsOfUse {
  COUNT = 1,
  PRICE = 2,
  NONE = 0
}

export const CouponTermsOfUseName: Record<number, string> = {
  [CouponTermsOfUse.COUNT]: "〇品以上購入する場合",
  [CouponTermsOfUse.PRICE]: "〇円以上購入する場合",
  [CouponTermsOfUse.NONE]: "条件なし"
};

// 管理ユーザー権限
export enum AdminUserType {
  ADMIN = 0,
  LOGISTIC = 1,
  CS = 2
}

export const AdminUserTypeName: Record<number, string> = {
  [AdminUserType.ADMIN]: "管理者",
  [AdminUserType.LOGISTIC]: "物流担当者",
  [AdminUserType.CS]: "CS対応担当者"
};

// サービスタイプ
export enum ServiceType {
  OFFICE_OKAN = 1,
  OKAN_STOOL = 2
}

export const ServiceTypeName: Record<number, string> = {
  [ServiceType.OFFICE_OKAN]: "オフィスおかん",
  [ServiceType.OKAN_STOOL]: "おかん便"
};

// カードブランド
export enum CardBrandType {
  VISA = "Visa",
  JCB = "JCB",
  MASTER = "MasterCard",
  DINERS = "Diners Club",
  AMEX = "American Express"
}

export const CardBrandTypeName: Record<string, string> = {
  [CardBrandType.VISA]: "visa",
  [CardBrandType.JCB]: "jcb",
  [CardBrandType.MASTER]: "master",
  [CardBrandType.DINERS]: "diners",
  [CardBrandType.AMEX]: "amex"
};
