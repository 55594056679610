import React from "react";
import Stack from "./Stack";

interface Props {
  text: string;
  imgPath: string;
}

const TextTitleIcon = (props: Props): JSX.Element => {
  return (
    <Stack horizontal justifyContent="center" alignItems="center">
      <img src={props.imgPath} alt="" />
      <div className="gl_text-h3 gl_color-redbrown-400">{props.text}</div>
    </Stack>
  );
};

export default TextTitleIcon;
