import { System503Error, System5xxError } from "src/types/libs/Errors";
import {
  HTTPClient as HTTPClientType,
  HTTPClientConfig,
  HTTPClientMethodOption,
  HTTPClientRequestConfig,
  HTTPClientResponse
} from "../types/libs/HTTPClient";

class HTTPClient implements HTTPClientType {
  config: HTTPClientConfig = {};

  constructor(config?: HTTPClientConfig) {
    this.config = config || {
      baseURL: process.env.REACT_APP_BFF_API_BASE_URL
    };
  }

  private static createBodyChunk(body: any): string | FormData | undefined {
    if (!body) {
      return;
    }

    if (typeof body === "string") {
      return body;
    }

    if (body instanceof FormData) {
      return body;
    }

    if (body instanceof URLSearchParams) {
      return body.toString();
    }

    return JSON.stringify(body);
  }

  private createURL(url: string, query?: Record<string, any>): string {
    const ref = /https?:\/\//.test(url) ? url : `${this.config.baseURL}${url}`;

    if (!query) {
      return ref;
    }

    const matches = ref.match(/^(?<path>[^?]+)(?<params>\?.*)?$/);
    if (!matches) {
      return ref;
    }

    const { path, params } = matches.groups || {};
    const searchParams = new URLSearchParams(params || "");
    Object.entries(query).forEach(([name, value]) => {
      searchParams.append(name, value);
    });

    return `${path}?${searchParams}`;
  }

  private request<
    T = any,
    R extends HTTPClientResponse = HTTPClientResponse<T>
  >(_url: string, _config?: HTTPClientRequestConfig): Promise<R> {
    const { body: _body, query, ...config } = _config || {};
    const url = this.createURL(_url, query);
    const body = HTTPClient.createBodyChunk(_body);

    return fetch(url, {
      ...config,
      body,
      headers: { ...this.config.headers, ...config.headers }
    }).then(async response => {
      const json: T = await response.json();
      return {
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        data: json
      } as R;
    });
  }

  delete<T = any, R extends HTTPClientResponse = HTTPClientResponse<T>>(
    url: string,
    options?: HTTPClientMethodOption
  ): Promise<R> {
    return this.request(url, { ...options, method: "DELETE" });
  }

  get<T = any, R extends HTTPClientResponse = HTTPClientResponse<T>>(
    url: string,
    options?: HTTPClientMethodOption
  ): Promise<R> {
    return this.request(url, { ...options, method: "GET" });
  }

  patch<T = any, R extends HTTPClientResponse = HTTPClientResponse<T>>(
    url: string,
    data?: any,
    options?: HTTPClientMethodOption
  ): Promise<R> {
    return this.request(url, { ...options, body: data, method: "PATCH" });
  }

  post<T = any, R extends HTTPClientResponse = HTTPClientResponse<T>>(
    url: string,
    data?: any,
    options?: HTTPClientMethodOption
  ): Promise<R> {
    return this.request(url, { ...options, body: data, method: "POST" });
  }

  put<T = any, R extends HTTPClientResponse = HTTPClientResponse<T>>(
    url: string,
    data?: any,
    options?: HTTPClientMethodOption
  ): Promise<R> {
    return this.request(url, { ...options, body: data, method: "PUT" });
  }
}

export const createHTTPClient = (config?: HTTPClientConfig): HTTPClientType =>
  new HTTPClient(config);

export default createHTTPClient();
