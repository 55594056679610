import logger from "./Logger";

export const liff = window.liff;

export const liffInit = async (liffId: string) => {
  if (liff.id && liff.getDecodedIDToken()) {
    logger.info("LIFF初期化済み");
  } else {
    logger.info(`LIFFを初期化: liffId = ${liffId}`);
    try {
      await liff.init({ liffId });
    } catch (error) {
      logger.error("LIFFの初期化に失敗", { error });
      throw error;
    }

    if (!liff.isLoggedIn()) {
      logger.info("LIFFログインを実行", { href: window.location.href });
      await liff.login({ redirectUri: window.location.href });
    }
  }

  // LIFFはIDトークンをキャッシュするため
  // 有効期限が切れていたらログアウトする
  if (
    !liff.getDecodedIDToken() ||
    liff.getDecodedIDToken().exp < new Date().getTime() / 1000 + 60
  ) {
    logger.info("LIFFの有効期限が近いためliffを一度ログアウト");
    liff.logout();
    await liff.login({ redirectUri: window.location.href });
  }
  logger.info(`LIFFの初期化完了: liffId = ${liffId}`);
};
