import React from "react";
import s from "./Divider.module.scss";

interface Props {
  margin?: boolean;
}

const Divider = (props: Props): JSX.Element => {
  return <div className={`${s.border} ${props.margin && s.margin}`}></div>;
};

export default Divider;
