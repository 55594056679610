import React from "react";
import s from "./ListNutrientsSimple.module.scss";

interface Props {
  // TODO: any型を変更すること
  nutrients: any[];
}

const ListNutrientsSimple = (props: Props): JSX.Element => {
  return (
    <div className={s.nutrients}>
      {props.nutrients.map((item, i) => (
        <div className={s.nutrient} key={i}>
          <div className="gl_text-xs gl_color-redbrown-400 gl_fontWeight-bold">
            {item.name}
          </div>
          <div>
            <span className="gl_text-h3 gl_color-redbrown-400">
              {item.kcal.toFixed(1)}
            </span>
            <span className="gl_text-xs gl_color-brown-300 gl_fontWeight-bold">
              {item.id === "calorie" ? " kcal" : " g"}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ListNutrientsSimple;
