import winston from "winston";
import dayjs from "dayjs";
import { ErrorLogMethod, LogMethod } from "../types/libs/Logger";

let user: any = null;
const addUser = winston.format(info => ({
  ...info,
  ...(!user ? null : { user })
}));

const _timestampFormat = (): string => dayjs().format("YYYY-MM-DDTHH:mm:ssZ");

const _logger = winston.createLogger({
  level: "info",
  format: winston.format.combine(
    winston.format.timestamp({ format: _timestampFormat } as any), // 型定義があっていないのでanyで回避
    addUser(),
    winston.format.json()
  ),
  transports: [new winston.transports.Console({ stderrLevels: ["error"] })],
  exitOnError: false
});

if (process.env.NODE_ENV === "production") {
  // TODO: prod環境はCloudWatch Logsに出力
}

const setUser = (data: any): void => (user = data);
const debug: LogMethod = (message, meta) => _logger.debug({ message, ...meta });
const info: LogMethod = (message, meta) => _logger.info({ message, ...meta });
const warn: LogMethod = (message, meta) => _logger.warn({ message, ...meta });
const error: ErrorLogMethod = (
  messageOrError: string | Error,
  meta?: Record<string, any>
) => {
  const infoObject: Record<string, any> = { ...meta };
  if (typeof messageOrError === "string") {
    infoObject.message = messageOrError;
  } else {
    infoObject.message = messageOrError.message;
    infoObject.stacktrace = (messageOrError.stack || "")
      .split("\n")
      .map(str => str.trim());
  }
  _logger.error(infoObject);
};

const logger = {
  setUser,
  debug,
  info,
  warn,
  error
};

export default logger;
