import React from "react";
import { Route } from "react-router-dom";
import { useLiffInit } from "src/hooks/useLiffInit";

export const InitRoute = (props: any) => {
  // トークンの期限切れ・ログインAPIの時点での５００系エラーの対応のため、ホーム画面に戻るたびにログイン処理を通す
  // すでにログイン済みかつ期限に余裕があれば何もリクエストしない
  const { ready } = useLiffInit();
  return ready ? <Route {...props} /> : <></>;
};
