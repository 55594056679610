import React from "react";
import s from "./CardGetCoupon.module.scss";
import Spacer from "./Spacer";
import TextLink from "./TextLink";

interface Props {
  name?: string;
}

const CardGetCoupon = (props: Props): JSX.Element => {
  return (
    <div className={s.card}>
      <div className={s.img1}></div>
      <div className={s.img2}></div>
      <Spacer size={1}></Spacer>
      <div className="gl_text-h1 gl_color-brown-400 gl_whitespace-prewrap gl_text-center">
        {props.name}
      </div>
      <Spacer size={2}></Spacer>
      <div style={{ height: "71px" }}></div>
      <Spacer size={4}></Spacer>
      <div>
        <div className="gl_text-sm">※決済時にご利用いただけます</div>
        <div className="gl_text-sm">
          ※
          <TextLink
            text="所有クーポンの確認"
            to={"/profiles/coupons"}
          ></TextLink>
        </div>
      </div>
    </div>
  );
};

export default CardGetCoupon;
