import React from "react";
import Card from "../UI/Card";
import Header from "../UI/Header";
import Layout from "../UI/Layout";
import { Helmet } from "react-helmet";

const TermsOfUse = (): JSX.Element => {
  return (
    <>
      <Helmet>
        <title>利用規約</title>
      </Helmet>
      <Header backText="戻る"></Header>
      <Layout>
        <Card>
          <div className="gl_document">
            <h1>オフィスおかんアプリ利用規約</h1>
            <hr />
            <h2>適用範囲</h2>
            <p>
              本規約は、「LINE」（運営：LINE株式会社）サービス上で機能する、株式会社OKAN（以下「当社」といいます。）提供の惣菜購入用アプリケーション「オフィスおかんアプリ」（以下「本アプリ」といいます。）の全ての利用者（以下「お客様」といいます。）と当社との関係を定めるものです。
            </p>
            <h2>規約へのご同意</h2>
            <p>
              本アプリの利用に際しては、お客様は本規約に同意していただく必要があります。本規約にご同意いただけないお客様は、本アプリ及び本アプリを通して提供するサービス（以下「本サービス」といいます。）をご利用できませんので、本アプリのご利用を中止してください。
            </p>
            <h2>規約の変更</h2>
            <p>
              当社は、本規約の内容を変更する場合があります。本アプリのインストール後に変更した場合を含め、本アプリの利用時点での規約を適用するものとします。
              <br />
              変更後の本規約にご同意いただけない場合は、直ちに本アプリを削除してください。
            </p>
            <h2>利用者情報</h2>
            <p>
              お客様による本アプリの利用に関連して当社が知り得るお客様の情報の管理及び取扱については、当社が別途定めるプライバシーポリシーによるものとします。
            </p>
            <h2>本アプリの利用</h2>
            <p>
              本アプリのインストール及び本アプリの利用にあたり通信料がかかる場合がありますが、この場合の通信料はすべてお客様のご負担とし、当社は一切負担しないものとします。
              <br />
              本アプリはオフィスおかんをご契約頂いている企業様でのみご利用頂けます。本アプリの決済機能はオフィスおかんに既に配達済みの惣菜、食事をご購入いただく機能です。オフィスに商品がない場合は、本アプリで代金をお支払いいただいても商品をご利用いただくことは出来ません。
            </p>
            <h2>本アプリの停止・変更・休止・廃止</h2>
            <p>
              以下の場合、本アプリが利用できない場合があります。なお、当社は、理由の如何によらず、本アプリが利用できないことによるすべての損害に対して一切の責任を負いません。
            </p>
            <ol>
              <li>
                サーバー、システム等に故障が生じた場合、もしくは定期または非定期のメンテナンスのために、システムの全部または一部を休止する場合。
              </li>
              <li>
                火災、停電、天災などの不可抗力により本アプリの提供が困難な場合。
              </li>
              <li>第一種電気通信事業者の任務が提供されない場合。</li>
              <li>
                お客様の端末の破損またはその他の事由による利用不能の場合。
              </li>
              <li>その他やむを得ない事由のある場合。</li>
            </ol>
            <h2>免責事項</h2>
            <p>
              当社は、当社の故意または重大な過失による場合を除き、以下に掲げるお客様または第三者に生じた損害について一切の責任を負わないものとします。
            </p>
            <ol>
              <li>
                本アプリのインストール及び本サービスの利用において生じた損害。
              </li>
              <li>
                お客様が本アプリをインストールした端末の障害及び回線の不通等により本アプリが利用不能となった場合、またその為に生じた損害。
              </li>
              <li>
                本アプリをインストールした端末の所有者以外の方が本サービスを利用した場合、その為に生じた損害。
              </li>
            </ol>
            <p>
              本サービスにおいて、第三者が提供しているサービス等へのリンクを提供している場合があります。当社はそれらのサービスやリソースを管理しておらず、それらのサービスやリソースについての一切の責任を負いかねます。従って、当社はそれらのサービス、広告、商品、アプリケーションなどに起因または関連して生じた一切の損害について直接間接を問わず賠償する責任を負いません。
            </p>
            <h2>知的財産権等</h2>
            <p>
              本アプリに関する一切の権利（所有権、特許権及び著作権等の知的財産権、肖像権、パブリシティー権等）は当社または当該権利を有する第三者に帰属します。当社はお客様に対し、本アプリに関する知的財産権について、本アプリを本規約に従ってお客様の端末においてのみ使用することができる、非独占的かつ譲渡不能の実施権ないし使用権を許諾するものとします。
            </p>
            <h2>禁止事項</h2>
            <p>お客様は、本アプリの利用にあたって以下の行為を禁止します。</p>
            <ol>
              <li>
                他のお客様、当社または第三者に不利益または損害を与える行為。
              </li>
              <li>公序良俗に反する行為。</li>
              <li>法令に違反する行為。</li>
              <li>虚偽の情報を本アプリ及び本サービスに提供する行為。</li>
              <li>
                当社の書面による事前の承認を得ずに、本アプリに関連して営利を追求する行為。
              </li>
              <li>本アプリ及び本サービスの運営を妨害する行為。</li>
              <li>本アプリ及び本サービスの信用を失墜または既存させる行為。</li>
              <li>当社の承認した以外の方法で本アプリを利用する行為。</li>
              <li>
                本アプリを複製、翻案、改変、譲渡、貸与、公衆送信または使用許諾する行為。
              </li>
              <li>
                本アプリを逆アセンブル、逆コンパイルまたはリバースエンジニアリングする行為。
              </li>
              <li>本規約に違反する行為。</li>
              <li>当社が不適切と判断する行為。</li>
            </ol>
            <h2>準拠法及び管轄</h2>
            <p>
              本規約の準拠法は日本法とします。
              <br />
              本規約、本アプリ及び本サービスに関して生じる一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
            </p>
            <p>制定日：2022年9月5日</p>

            {/* <p>
              本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。
              <a
                href="https://okan.co.jp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                リンクテキスト
              </a>
              。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。
            </p>
            <ul>
              <li>リストテキスト</li>
              <li>リストテキスト</li>
            </ul>
            <ol>
              <li>番号付きリストテキスト</li>
              <li>番号付きリストテキスト</li>
            </ol>
            <h3>H3見出し</h3>
            <p>
              本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。
            </p>
            <hr />
            <h2>H2見出し</h2>
            <p>
              本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。
              <a
                href="https://okan.co.jp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                リンクテキスト
              </a>
              。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。
            </p>
            <ul>
              <li>リストテキスト</li>
              <li>リストテキスト</li>
            </ul>
            <ol>
              <li>番号付きリストテキスト</li>
              <li>番号付きリストテキスト</li>
            </ol>
            <h3>H3見出し</h3>
            <p>
              本文テキストが入ります。本文テキストが入ります。本文テキストが入ります。
            </p> */}
          </div>
        </Card>
      </Layout>
    </>
  );
};

export default TermsOfUse;
