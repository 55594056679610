import React, { useEffect, useMemo, useState } from "react";
import CardItemMenu, { Allergy, Nutrient, User } from "../UI/CardItemMenu";
import CardItemMenuSimple from "../UI/CardItemMenuSimple";
import Footer from "../UI/Footer";
import FooterCart from "../UI/FooterCart";
import Header from "../UI/Header";
import Layout from "../UI/Layout";
import Spacer from "../UI/Spacer";
import s from "./ItemId.module.scss";
import { Helmet } from "react-helmet";
import { RouteComponentProps, useHistory } from "react-router-dom";
import ApiCaller, {
  Item,
  ItemResponse,
  NutrientIntakeValues
} from "src/libs/ApiWrapper";
import dayjs from "dayjs";
import { GenderType } from "src/libs/types/enums";
import GenericErrorScreen from "../Screens/GenericErrorScreen";
import useAppStateDispatch from "src/hooks/useAppStateDispatch";
import useAppState from "src/hooks/useAppState";
import { System503Error, System5xxError } from "src/types/libs/Errors";
import { useHandleException } from "src/hooks/useHandleException";

const ItemId = (props: RouteComponentProps<{ id: string }>): JSX.Element => {
  const globalState = useAppState();
  const history = useHistory();
  const user = globalState.user;
  const age = dayjs().diff(dayjs(user?.birthday), "year");
  const [item, setItem] = useState<Item>();
  const [itemNotFound, setItemNotFound] = useState<boolean>(false);
  const [nutrientIntakeValues, setNutrientIntakeValues] = useState<
    NutrientIntakeValues
  >();
  const itemId = props.match.params.id;
  const API = new ApiCaller();
  const dispatch = useAppStateDispatch();
  const handleException = useHandleException();

  const nutrients: Nutrient[] = useMemo(
    () => [
      {
        id: "calorie",
        name: "エネルギー",
        kcal: item?.calorie,
        totalKcal: nutrientIntakeValues?.calorie
      },
      {
        id: "protein",
        name: "たんぱく質",
        kcal: item?.protein,
        totalKcal: nutrientIntakeValues?.protein
      },
      {
        id: "lipid",
        name: "脂質",
        kcal: item?.lipid,
        totalKcal: nutrientIntakeValues?.lipid
      },
      {
        id: "carbohydrates",
        name: "炭水化物",
        kcal: item?.carbohydrates,
        totalKcal: nutrientIntakeValues?.carbohydrates
      },
      {
        id: "salt",
        name: "食塩相当量",
        kcal: item?.salt,
        totalKcal: nutrientIntakeValues?.salt
      }
    ],
    [item, nutrientIntakeValues]
  );

  const baseAllergies = [
    "えび",
    "かに",
    "小麦",
    "そば",
    "卵",
    "乳",
    "落花生",
    "アーモンド",
    "あわび",
    "いか",
    "いくら",
    "オレンジ",
    "カシューナッツ",
    "キウイフルーツ",
    "牛肉",
    "くるみ",
    "ごま",
    "さけ",
    "さば",
    "大豆",
    "鶏肉",
    "バナナ",
    "豚肉",
    "まつたけ",
    "もも",
    "やまいも",
    "りんご",
    "ゼラチン"
  ];

  const allergySels = (foodAllergyString: string | null): Allergy[] => {
    const foodAllergies = foodAllergyString?.split(",") || [];
    return baseAllergies.map(allergy => ({
      name: allergy,
      active: foodAllergies.includes(allergy)
    }));
  };

  const detailItemResponse = (resp: ItemResponse): void => {
    if (resp.data?.item) {
      setItem(resp.data.item);
    } else {
      setItemNotFound(true);
    }
  };

  const nutrientIntakeValuesResponse = (resp: NutrientIntakeValues): void => {
    setNutrientIntakeValues(resp);
  };

  const addItemToCart = () => {
    if (item) {
      dispatch({
        type: "addItemAction",
        payload: {
          item: {
            itemId: item.id,
            price: item.price,
            name: item.name,
            mainImg: item.mainImg,
            thumbImg: item.thumbImg,
            calorie: item.calorie,
            protein: item.protein,
            lipid: item.lipid,
            carbohydrates: item.carbohydrates,
            salt: item.salt,
            ingredient: item.ingredient
          }
        }
      });
    }
  };

  useEffect(() => {
    Promise.resolve().then(async () => {
      await API.getDetailItem(itemId, detailItemResponse).catch(err => {
        handleException(err);
      });
      if (user.id) {
        await API.getNutrientIntakeValues(
          age,
          user?.gender,
          nutrientIntakeValuesResponse
        ).catch(err => {
          handleException(err);
        });
      }
      const itemIdPage = document.getElementById("ItemIdPage");
      setTimeout(() => {
        itemIdPage!.classList.remove(s.fadeIn);
      }, 1200);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (itemNotFound) {
    return <GenericErrorScreen queryString={{ errorType: "notFound" }} />;
  }

  if (!item) {
    return <></>;
  }

  return (
    <>
      <Helmet>
        <title>商品詳細</title>
      </Helmet>
      <div id="ItemIdPage" className={s.fadeIn}>
        <Header backText="戻る" account></Header>
        <div
          className={s.img}
          style={{
            backgroundImage: `url(${item?.mainImg})`
          }}
        ></div>
        <Layout noFooter>
          <div className={s.cardWrap}>
            {user && user.birthday && user.gender < GenderType.OTHER ? (
              <CardItemMenu
                user={user}
                name={item.name}
                description={item.description}
                ingredient={item.ingredient}
                nutrients={nutrients}
                allergySels={allergySels(item.foodAllergies)}
              ></CardItemMenu>
            ) : (
              <CardItemMenuSimple
                name={item.name}
                description={item.description}
                nutrients={nutrients}
                ingredient={item.ingredient}
                allergySels={allergySels(item.foodAllergies)}
              />
            )}
            <Spacer size={5}></Spacer>
            <Footer></Footer>
          </div>
        </Layout>
        <Spacer size={20}></Spacer>
        <FooterCart isAddItem price={100} addItem={addItemToCart}></FooterCart>
      </div>
    </>
  );
};

export default ItemId;
