import React from "react";
import s from "./ListItem.module.scss";
import IconAngleRight from "../../assets/images/icon_angle-right.svg";
import { useHistory } from "react-router-dom";

interface Props {
  text: string;
  icon: string;
  to?: string;
}

const ListItem = (props: Props): JSX.Element => {
  const history = useHistory();
  return (
    <div
      className={s.listItem}
      onClick={() => props.to && history.push(props.to)}
    >
      <div className={s.listItem_left}>
        <img src={props.icon} alt={props.text} width="28" height="28" />
        <span className={s.listItem_left_text}>{props.text}</span>
      </div>
      <img src={IconAngleRight} alt="" />
    </div>
  );
};

export default ListItem;
