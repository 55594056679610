import React from "react";
import s from "./ButtonSelect.module.scss";

interface Props {
  text: string;
  active?: boolean;
  onClick?: () => void;
}

const ButtonSelect = (props: Props): JSX.Element => {
  return (
    <button
      type="button"
      className={props.active ? s.button_active : s.button_default}
      onClick={() => props.onClick && props.onClick()}
    >
      {props.text}
    </button>
  );
};

export default ButtonSelect;
