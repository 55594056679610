import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { useHandleException } from "src/hooks/useHandleException";
import ApiCaller, { CardInfo } from "src/libs/ApiWrapper";
import Card from "../UI/Card";
import FormCards from "../UI/FormCard";
import Header from "../UI/Header";
import Layout from "../UI/Layout";
import ListItemCreditCard from "../UI/ListItemCreditCard";
import Loading from "../UI/Loading";
import Stack from "../UI/Stack";

// クレジットカード一覧画面 - /credit_cards
const CreditCards = (): JSX.Element => {
  const [cards, setCards] = useState<CardInfo[]>([]);
  const API = new ApiCaller();
  const history = useHistory();
  const handleException = useHandleException();
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    Promise.resolve().then(async () => {
      const cards = await API.getCards().catch(err => {
        handleException(err);
      });
      setCards(cards || []);
      setShowLoading(false);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>クレジットカード登録</title>
      </Helmet>
      {showLoading && <Loading />}
      <Header backText="戻る"></Header>
      <Layout>
        <Stack size={5}>
          {cards.length !== 0 ? (
            <Card>
              <Stack size={4}>
                {cards.map((card, i) => {
                  return (
                    <ListItemCreditCard
                      id={card.id}
                      brand={card.brand}
                      expYear={card.expYear}
                      expMonth={card.expMonth}
                      number={card.last4}
                      setCards={setCards}
                      setShowLoading={setShowLoading}
                    ></ListItemCreditCard>
                  );
                })}
              </Stack>
            </Card>
          ) : (
            <></>
          )}
          <div>
            <FormCards setCards={setCards} setShowLoading={setShowLoading} />
          </div>
        </Stack>
      </Layout>
    </>
  );
};

export default CreditCards;
