import React from "react";
import s from "./TextCaption.module.scss";

interface Props {
  text: string;
  size?: "xs";
  color?: "300" | "400";
  weight?: "600" | "700";
}

const TextCaption = (props: Props): JSX.Element => {
  return (
    <span
      className={`${s.base} ${
        props.size ? s[`size_${props.size}`] : s["size_xxs"]
      } ${props.color ? s[`color_${props.color}`] : s["color_400"]} ${
        props.weight ? s[`weight_${props.weight}`] : s["weight_400"]
      }`}
    >
      {props.text}
    </span>
  );
};

export default TextCaption;
