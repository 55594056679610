import React from "react";
import ButtonIconCircle from "./ButtonIconCircle";
import ImgCardBrand from "./ImgCardBrand";
import s from "./ListItemCreditCard.module.scss";
import Stack from "./Stack";

interface Props {
  id: string;
  checkActive?: boolean;
  onClickCheck?: () => void;
}

const ListItemCreditCardNew = (props: Props): JSX.Element => {
  return (
    <>
      <div key={props.id} className={s.item}>
        <Stack horizontal justifyContent="space-between" alignItems="center">
          {/* left */}
          <Stack horizontal alignItems="center" size={2}>
            {/* img */}
            <ImgCardBrand brand="ADD"></ImgCardBrand>
            {/* cardInfo */}
            <div className="gl_text-sm gl_color-brown-400">
              新しいカードを追加する
            </div>
          </Stack>
          {/* right */}
          <ButtonIconCircle
            icon={props.checkActive ? "check" : "none"}
            active={props.checkActive}
            onClick={() => props.onClickCheck && props.onClickCheck()}
          ></ButtonIconCircle>
        </Stack>
      </div>
    </>
  );
};

export default ListItemCreditCardNew;
