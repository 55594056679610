import React from "react";
import Layout from "../UI/Layout";
import Stack from "../UI/Stack";
import IconFace from "../../assets/images/icon_face.svg";
import IconCart from "../../assets/images/icon_cart.svg";
import IconTag from "../../assets/images/icon_tag.svg";
import IconCard from "../../assets/images/icon_card.svg";
import Divider from "../UI/Divider";
import ListItem from "../UI/ListItem";
import TextLink from "../UI/TextLink";
import Header from "../UI/Header";
import Card from "../UI/Card";
import { Helmet } from "react-helmet";
import { liff } from "src/libs/liff";
import logger from "src/libs/Logger";

const Profiles = (): JSX.Element => {
  const sendFaqMessage = () => {
    logger.info("お問合せメッセージ送信");
    if (liff.isInClient()) {
      liff
        .sendMessages([
          {
            type: "text",
            text: "> よくあるご質問"
          }
        ])
        .then(() => {
          liff.closeWindow();
        });
    }
  };
  return (
    <>
      <Helmet>
        <title>マイページ</title>
      </Helmet>
      <Header backText="戻る"></Header>
      <Layout>
        <Stack size={5}>
          <Card paddingX>
            <ListItem
              text="ユーザー情報"
              icon={IconFace}
              to="/profiles/accounts/edit"
            ></ListItem>
            <Divider></Divider>
            <ListItem
              text="購入履歴"
              icon={IconCart}
              to="/profiles/order-histories"
            ></ListItem>
            <Divider></Divider>
            <ListItem
              text="所有クーポン"
              icon={IconTag}
              to="/profiles/coupons"
            ></ListItem>
            <Divider></Divider>
            <ListItem
              text="クレジットカード登録"
              icon={IconCard}
              to="/credit_cards"
            ></ListItem>
          </Card>
          <Stack size={4}>
            <TextLink
              text="よくあるご質問"
              onClick={() => sendFaqMessage()}
            ></TextLink>
            <TextLink text="利用規約" to="/terms_of_use/email"></TextLink>
            <TextLink
              text="プライバシーポリシー"
              to="/privacy_policy/profile"
            ></TextLink>
            <TextLink
              text="特定商取引法に基づく表示"
              to="/specified_commercial_transaction_act"
            ></TextLink>
            <TextLink text="運営会社" to="/company"></TextLink>
            <TextLink text="退会" to="/withdrawal"></TextLink>
          </Stack>
        </Stack>
      </Layout>
    </>
  );
};

export default Profiles;
