import React from "react";
import Stack from "./Stack";

interface Props {
  checked?: boolean;
  label: string;
  onClick: () => void;
}

const FormItemCheckbox = (props: Props): JSX.Element => {
  return (
    <div className="gl_inline-block" onClick={() => props.onClick()}>
      <Stack horizontal alignItems="center">
        <img
          src={require(`../../assets/new-images/icon-sm-checkbox-${
            props.checked ? "on" : "off"
          }.svg`)}
          alt=""
        />
        <div className="gl_text-sm gl_color-brown-400">{props.label}</div>
      </Stack>
    </div>
  );
};

export default FormItemCheckbox;
