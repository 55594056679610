import React from "react";
import { useHistory } from "react-router-dom";
import s from "./ListItemMenu.module.scss";
import Stack from "./Stack";

interface Props {
  id: number;
  imgPath: string;
  name: string;
  price: number;
  onClick: () => void;
}

const ListItemMenu = (props: Props): JSX.Element => {
  const history = useHistory();
  return (
    <div className={s.card} onClick={() => history.push(`/item/${props.id}`)}>
      <Stack horizontal>
        <div
          className={s.img}
          style={{
            backgroundImage: `url(${props.imgPath})`,
          }}
        ></div>
        <div className={s.content}>
          <div className={s.name}>{props.name}</div>
        </div>
      </Stack>
      <span
        className={s.button}
        onClick={(e) => {
          e.stopPropagation();
          console.log(`add!${props.id}`);
          props.onClick();
        }}
      >
        <div className={s.button_price}>¥{props.price.toLocaleString()}</div>
        <img
          className={s.button_cart}
          src={require("../../assets/new-images/icon-lg-cartAdd-orange.svg")}
          alt=""
        />
      </span>
    </div>
  );
};

export default ListItemMenu;
