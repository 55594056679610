import React from "react";
import s from "./ItemAllergySel.module.scss";

interface Props {
  name: string;
  active?: boolean;
}

const ItemAllergySel = (props: Props): JSX.Element => {
  return (
    <div className={`${s.base} ${props.active && s.active}`}>
      <span
        className={`${s.text} gl_text-xs ${props.active &&
          "gl_fontWeight-bold"}`}
      >
        {props.name}
      </span>
    </div>
  );
};

export default ItemAllergySel;
