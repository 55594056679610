import React from "react";
import AppContext from "../contexts/AppContext";
import * as Types from "../types/contexts/AppContext";

const useAppContext = (): Types.AppContext => {
  const appContext = React.useContext(AppContext);
  if (!appContext) {
    throw new Error("Not initialized");
  }
  return appContext;
};

export default useAppContext;
