import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import AppContextProvider from "./AppContextProvider";
import "./asset";
import ScreenManagement from "./ScreenManagement";

interface Attributes {
  box: { boxId: number };
  items: {
    description: string;
    id: number;
    itemCategoryId: number;
    itemCategoryName: string;
    mainImg: string;
    name: string;
    order: number;
    price: number;
    thumbImg: string;
  }[];
  itemCategories: {
    categoryImg: string;
    id: number;
    name: string;
    order: number;
  }[];
  favorites: {
    id: number;
    itemId: number;
    userId: number;
  }[];
  orders: {
    itemId: number;
    mainImg: string;
    name: string;
    price: number;
    quantity: number;
    thumbImg: string;
  }[];
}

interface DecryptedBoxId {
  isValidDigit: boolean;
  decryptedBoxId: number;
}

interface DataResponse {
  data: Attributes;
}
function App(): React.ReactElement {
  const [mounted, setMounted] = useState(false);
  const [stateData, setStateData] = useState(Object);

  useEffect(() => {
    setMounted(true);
    return;
  }, []);

  const { width } = useWindowDimensions();
  const [viewportOption, setViewportOption] = useState(
    "width=device-width, initial-scale=1"
  );
  useEffect(() => {
    if (width >= 375) {
      setViewportOption("width=device-width, initial-scale=1");
    } else {
      setViewportOption("width=360");
    }
  }, [viewportOption]);

  return (
    <AppContextProvider>
      <Helmet>
        <meta name="viewport" content={viewportOption} />
      </Helmet>
      <ScreenManagement data={stateData} />
    </AppContextProvider>
  );
}

export default App;
