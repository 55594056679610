import React, {
  BaseSyntheticEvent,
  Ref,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState
} from "react";
import Autosuggest from "react-autosuggest";
import s from "./FormSearchItems.module.scss";

// react-autosuggestのドキュメント
// https://github.com/moroshko/react-autosuggest

type MenuType = {
  id: number;
  name: string;
};

export type Items = {
  id: number;
  itemCategoryId: number;
  name: string;
  price: number;
  mainImg: string;
  thumbImg: string;
};

interface Props {
  menuItems: Items[];
  onSearch: (word: string) => void;
}

const FormSearchItems = React.forwardRef(
  (props: Props, ref: Ref<{ resetValue: () => void }>): JSX.Element => {
    const [value, setValue] = useState("");
    const [suggestions, setSuggestions] = useState<MenuType[]>([]);
    const { menuItems, onSearch } = props;

    const menus: MenuType[] = useMemo(() => {
      return menuItems.map(item => {
        return {
          id: item.id,
          name: item.name
        };
      });
    }, [menuItems]);

    const getSuggestions = useCallback(
      (value: string): MenuType[] => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0
          ? []
          : menus.filter(menu => menu.name.indexOf(inputValue) > -1);
      },
      [menus]
    );

    const getSuggestionValue = (suggestion: MenuType): string => {
      const { name } = suggestion;
      return name;
    };

    const renderSuggestion = (suggestion: MenuType): JSX.Element => {
      return <div>{suggestion.name}</div>;
    };

    const onChange = (
      event: BaseSyntheticEvent,
      { newValue }: { newValue: string }
    ): void => {
      if (event) setValue(newValue);
    };

    const onSuggestionsFetchRequested = ({
      value
    }: {
      value: string;
    }): void => {
      const suggestions: MenuType[] = getSuggestions(value);
      setSuggestions(suggestions);
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    const onSuggestionsClearRequested = (): void => {
      setSuggestions([]);
    };

    const handleClearInput = (): void => {
      setValue("");
      onSearch("");
    };

    const handleSearch = (): void => {
      onSearch(value);
    };

    const inputProps = {
      placeholder: "検索...",
      value,
      onChange
    };

    useImperativeHandle(ref, () => ({
      resetValue: (): void => {
        setValue("");
      }
    }));

    return (
      <div className={`${s.form}`}>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          theme={{
            container: s.container,
            input: s.input,
            suggestionsContainer: s.suggestionsContainer,
            suggestion: s.suggestion
          }}
        />
        <div className={s.submit}>
          <button onClick={handleClearInput}>
            <img
              src={require("../../assets/new-images/icon-md-close.svg")}
              alt=""
              width={28}
              height={28}
            />
          </button>
          <button onClick={handleSearch}>
            <img
              src={require("../../assets/new-images/icon-md-search.svg")}
              alt=""
              width={28}
              height={28}
            />
          </button>
        </div>
      </div>
    );
  }
);

export default FormSearchItems;
