import React from "react";
import s from "./ListItemOrderItem.module.scss";
import Spacer from "./Spacer";
import Stack from "./Stack";

interface Props {
  imgPath: string;
  name: string;
  price: number;
  count: number;
}

const ListItemOrderItem = (props: Props): JSX.Element => {
  const totalPrice = (props.price * props.count).toLocaleString();
  return (
    <Stack horizontal>
      <div
        className={s.img}
        style={{
          backgroundImage: `url(${props.imgPath})`,
        }}
      ></div>
      <div className={`${s.content} gl_flex-1`}>
        <div className={`${s.name} gl_text-h3`}>{props.name}</div>
        <Spacer size={1}></Spacer>
        <Stack horizontal justifyContent="space-between" alignItems="flex-end">
          <div className="gl_text-xs gl_color-brown-400">
            価格：¥{props.price.toLocaleString()}
            <br />
            個数：{props.count}
          </div>
          <div className="gl_fontWeight-bold">¥ {totalPrice}</div>
        </Stack>
      </div>
    </Stack>
  );
};

export default ListItemOrderItem;
