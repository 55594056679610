import React from "react";
import ButtonPrimary from "./ButtonPrimary";
import Stack from "./Stack";
import s from "./ModalInnerWarning.module.scss";

interface Props {
  title: string;
  submitButtonText: string;
  onClickSubmit: () => void;
  onClickCancel: () => void;
}

const ModalInnerWarning = (props: Props): JSX.Element => {
  const onClickClose = async () => {
    const modalEl = document.getElementById("Modal");
    if (modalEl) {
      modalEl.classList.add(s.anime_fadeOut);
      await new Promise((r) => setTimeout(r, 300));
      modalEl.classList.remove(s.anime_fadeOut);
      props.onClickCancel();
    }
  };

  return (
    <div className={s.padding}>
      <Stack size={6}>
        <div className="gl_text-center">
          <img
            src={require("../../assets/new-images/icon-xl-attention.svg")}
            alt=""
            width="76"
          />
        </div>
        <div className="gl_text-h1 gl_text-center gl_whitespace-prewrap">
          {props.title}
        </div>
        <div className="gl_text-center">
          <ButtonPrimary
            text={props.submitButtonText}
            size="md"
            color="danger"
            onClick={() => props.onClickSubmit()}
          ></ButtonPrimary>
        </div>
        <div className="gl_text-center">
          <ButtonPrimary
            text="キャンセル"
            size="md"
            color="cancel"
            outlined
            onClick={() => onClickClose()}
          ></ButtonPrimary>
        </div>
      </Stack>
    </div>
  );
};

export default ModalInnerWarning;
