import React from "react";
import Card from "./Card";
import Stack from "./Stack";
import TextTag from "./TextTag";
import dayjs from "dayjs";
import { CouponEffect, CouponTermsOfUse } from "src/libs/types/enums";

interface Props {
  name: string;
  effect: number;
  effectValue1: number;
  effectValue2?: number;
  expiresAt: string;
  termsOfUse: number;
  termsOfUseValue: number;
  note: string;
}

const CardCoupon = (props: Props): JSX.Element => {
  let effectText = "";
  if (props.effect === CouponEffect.PRICE)
    effectText = `${props.effectValue1.toLocaleString()}円引き`;
  if (props.effect === CouponEffect.PERCENT)
    effectText = `${props.effectValue1}%割引`;
  if (props.effect === CouponEffect.FREE) {
    if (!props.effectValue2) {
      effectText = `${props.effectValue1}品無料`;
    } else {
      effectText = `${props.effectValue2.toLocaleString()}円商品が${
        props.effectValue1
      }品無料`;
    }
  }

  let termsOfUseText = "条件なし";
  if (props.termsOfUse === CouponTermsOfUse.COUNT)
    termsOfUseText = `${props.termsOfUseValue}品以上購入する場合`;
  if (props.termsOfUse === CouponTermsOfUse.PRICE)
    termsOfUseText = `${props.termsOfUseValue.toLocaleString()}円以上購入する場合`;

  const isExpiration = dayjs()
    .add(5, "d")
    .isAfter(dayjs(props.expiresAt));

  const formattedExpiresAt = dayjs(props.expiresAt).format("YYYY年MM月DD日");
  console.log(props.expiresAt)
  const expiresAtText = `${formattedExpiresAt}まで`;
  return (
    <Card>
      <Stack size={4}>
        <Stack horizontal size={1} alignItems="center">
          <img
            src={require("../../assets/new-images/icon-md-coupon-redbrown.svg")}
            alt=""
            width={28}
          />
          <div className="gl_color-brown-400 gl_fontWeight-bold">
            {props.name}
          </div>
        </Stack>
        <Stack horizontal size={2} alignItems="center">
          <TextTag text="割引内容"></TextTag>
          <div className="gl_color-orange-500 gl_fontWeight-bold gl_text-sm">
            {effectText}
          </div>
        </Stack>
        <Stack horizontal size={2} alignItems="center">
          <TextTag text="有効期限"></TextTag>
          <div
            className={`${isExpiration &&
              "gl_color-pink-500 gl_fontWeight-bold"} gl_text-sm`}
          >
            {expiresAtText}
          </div>
        </Stack>
        <Stack horizontal size={2} alignItems="center">
          <TextTag text="利用条件"></TextTag>
          <div className="gl_text-sm">{termsOfUseText}</div>
        </Stack>
        <div className="gl_text-sm gl_whitespace-prewrap">{props.note}</div>
      </Stack>
    </Card>
  );
};

export default CardCoupon;
