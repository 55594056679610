import React from "react";
import ButtonIconCircle from "./ButtonIconCircle";
import Divider from "./Divider";
import s from "./ListItemCartMenu.module.scss";
import Stack from "./Stack";

interface Props {
  imgPath: string;
  name: string;
  price: number;
  count: number;
  last?: boolean;
  onClickPlus: () => void;
  onClickMinus: () => void;
  onClickDelete: () => void;
}

const ListItemCartMenu = (props: Props): JSX.Element => {
  return (
    <>
      <Stack horizontal size={2}>
        {/* img */}
        <div
          className={s.img}
          style={{
            backgroundImage: `url(${props.imgPath})`,
          }}
        ></div>
        {/* content */}
        <div className="gl_flex-1">
          <Stack horizontal justifyContent="space-between">
            <div className="gl_text-h3 gl_color-redbrown-400 gl_fontWeight-bold">
              {props.name}
            </div>
            <div onClick={() => props.onClickDelete()}>
              <img
                src={require("../../assets/new-images/icon-sm-close.svg")}
                alt=""
              />
            </div>
          </Stack>
          <Stack horizontal alignItems="center" size={2}>
            <div className="gl_flex-1 gl_text-h3 gl_fontWeight-bold">
              ¥ {props.price.toLocaleString()}
            </div>
            <ButtonIconCircle
              icon="minus"
              active={props.count > 0}
              onClick={() => props.onClickMinus()}
            ></ButtonIconCircle>
            <div className="gl_text-h3 gl_fontWeight-bold">{props.count}</div>
            <ButtonIconCircle
              icon="plus"
              active
              onClick={() => props.onClickPlus()}
            ></ButtonIconCircle>
          </Stack>
        </div>
      </Stack>
      {!props.last && <Divider></Divider>}
    </>
  );
};

export default ListItemCartMenu;
