import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { GenderType } from "src/libs/types/enums";
import ButtonSelect from "./ButtonSelect";
import Card from "./Card";
import FormSelect from "./FormSelect";
import Label from "./Label";
import Stack from "./Stack";
import TextCaption from "./TextCaption";
import TextLink from "./TextLink";
import TextTitle from "./TextTitle";

interface Props {
  signUp?: boolean;
  gender: GenderType;
  defaultInput?: {
    year: string;
    month: string;
    day: string;
  };
  setGender: (val: GenderType) => void;
  setDisabled: (val: boolean) => void;
  setBirthday: (val: string) => void;
}

const FormUserInfo = (props: Props): JSX.Element => {
  const [year, setYear] = useState(
    props.defaultInput ? props.defaultInput.year : ""
  );
  const [month, setMonth] = useState(
    props.defaultInput ? props.defaultInput.month : ""
  );
  const [day, setDay] = useState(
    props.defaultInput ? props.defaultInput.day : ""
  );
  const [days, setDays] = useState<string[]>([]);
  const [birthdayErrorMessage, setBirthdayErrorMessage] = useState("");
  const years = [];
  for (let i = new Date().getFullYear(); i >= 1920; i--) {
    years.push(String(i));
  }
  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12"
  ];
  const daysBase = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31"
  ];

  const resetBirthday = () => {
    setYear("");
    setMonth("");
    setDay("");
  };

  useEffect(() => {
    if (year && month) {
      const daysInMonth = dayjs(`${year}-${month}-01`).daysInMonth();
      if (day && Number(day) > daysInMonth) {
        setDay("");
      }
      setDays(
        [...Array(daysInMonth)].map((_, i) =>
          (i + 1).toString().padStart(2, "0")
        )
      );
    } else {
      setDays(daysBase);
    }

    if (year && month && day) {
      const birthday = `${year}/${month}/${day}`;
      const delimiterDate = dayjs().add(-15, "y");
      if (delimiterDate.isBefore(dayjs(birthday))) {
        setBirthdayErrorMessage("15歳未満はご利用いただけません。");
        props.setDisabled(true);
      } else {
        setBirthdayErrorMessage("");
        props.setBirthday(birthday);
        props.setDisabled(false);
      }
    } else if (!year && !month && !day) {
      setBirthdayErrorMessage("");
      props.setBirthday("")
      props.setDisabled(false);
    } else {
      setBirthdayErrorMessage("全て選択してください。");
      props.setDisabled(true);
    }
  }, [year, month, day]);

  return (
    <Card backgroundImg={props.signUp}>
      <Stack size={4}>
        {props.signUp && <TextTitle center text="ユーザー登録"></TextTitle>}
        <Stack size={2}>
          <Label text="性別"></Label>
          <Stack horizontal size={2}>
            <ButtonSelect
              text="男性"
              active={props.gender === GenderType.MALE}
              onClick={() => props.setGender(GenderType.MALE)}
            ></ButtonSelect>
            <ButtonSelect
              text="女性"
              active={props.gender === GenderType.FAMALE}
              onClick={() => props.setGender(GenderType.FAMALE)}
            ></ButtonSelect>
            <ButtonSelect
              text="その他"
              active={props.gender === GenderType.OTHER}
              onClick={() => props.setGender(GenderType.OTHER)}
            ></ButtonSelect>
            <ButtonSelect
              text={`回答\nしない`}
              active={props.gender === GenderType.noanswer}
              onClick={() => props.setGender(GenderType.noanswer)}
            ></ButtonSelect>
          </Stack>
        </Stack>
        <Stack size={2}>
          <Label text="生年月日"></Label>
          <Stack horizontal size={2} alignItems="center">
            <FormSelect
              list={years}
              value={year}
              placeholder="1990"
              set={setYear}
            ></FormSelect>
            <FormSelect
              list={months}
              value={month}
              placeholder="01"
              set={setMonth}
            ></FormSelect>
            <FormSelect
              list={days}
              value={day}
              placeholder="01"
              set={setDay}
            ></FormSelect>
            <TextLink
              text="リセット"
              onClick={() => resetBirthday()}
            ></TextLink>
          </Stack>
          {birthdayErrorMessage ? (
            <span style={{ fontSize: "12px", color: "#fa377a" }}>
              {birthdayErrorMessage}
            </span>
          ) : (
            <></>
          )}
        </Stack>
        <TextCaption text="※性別・生年月日は1日の食事摂取量基準の算出に使用します"></TextCaption>
      </Stack>
    </Card>
  );
};

export default FormUserInfo;
