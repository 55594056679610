import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
  horizontal?: boolean;
  size?: number;
  justifyContent?: "center" | "space-between";
  alignItems?: "center" | "flex-end";
}

const Stack = (props: Props): JSX.Element => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: props.horizontal ? "row" : "column",
        gap: props.size ? `${props.size * 4}px` : "0px",
        justifyContent: props.justifyContent ? props.justifyContent : "normal",
        alignItems: props.alignItems ? props.alignItems : "normal",
      }}
    >
      {props.children}
    </div>
  );
};

export default Stack;
