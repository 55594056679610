import React from "react";
import s from "./TextTitle.module.scss";

interface Props {
  text: string;
  center?: boolean;
  size?: "lg";
  color?: "brown";
}

const TextTitle = (props: Props): JSX.Element => {
  const textSize = props.size ? `size_${props.size}` : "size_md";
  const textColor = props.color ? `color_${props.color}` : "color_redbrown";
  return (
    <div className={props.center ? s.text_center : ""}>
      <span className={`${s.title} ${s[textSize]} ${s[textColor]}`}>
        {props.text}
      </span>
    </div>
  );
};

export default TextTitle;
