import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import ButtonPrimary from "../UI/ButtonPrimary";
import FormBoxId from "../UI/FormBoxId";
import FormUserInfo from "../UI/FormUserInfo";
import Header from "../UI/Header";
import Layout from "../UI/Layout";
import Stack from "../UI/Stack";
import { Helmet } from "react-helmet";
import { GenderType } from "src/libs/types/enums";
import useAppStateDispatch from "src/hooks/useAppStateDispatch";
import ApiCaller, {
  UserOptionsResponse,
  UserResponse
} from "src/libs/ApiWrapper";
import dayjs from "dayjs";
import useAppState from "src/hooks/useAppState";
import { useHandleException } from "src/hooks/useHandleException";

interface DefaultInput {
  year: string;
  month: string;
  day: string;
}

// プロフィール変更画面 - /profiles/accounts/edit
const ProfilesAccounts = (): JSX.Element => {
  const [gender, setGender] = useState<GenderType>(GenderType.MALE);
  const [boxId, setBoxId] = useState("");
  const [show, setShow] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [birthday, setBirthday] = useState("");
  const [defaultInput, setDefaultInput] = useState<DefaultInput | undefined>();
  const dispatch = useAppStateDispatch();
  const API = new ApiCaller();
  const boxIdRef = useRef<any>();
  const globalState = useAppState();
  const userId = globalState.user.id;
  const handleException = useHandleException();
  const history = useHistory();

  const updateResponse = (resp: UserResponse) => {
    if (boxId) {
      localStorage.setItem("boxId", boxId);
      localStorage.setItem("encryptedBoxId", boxIdRef.current.encryptedBoxId());
    } else {
      localStorage.removeItem("boxId");
      localStorage.removeItem("encryptedBoxId");
    }
    dispatch({
      type: "updateUserDataAction",
      payload: {
        user: {
          id: resp.id,
          birthday: resp.birthday,
          gender: resp.gender,
          boxId: resp.boxId
        }
      }
    });
    dispatch({
      type: "setAlertMessage",
      payload: {
        alertMessage: {
          type: "success",
          message: "ユーザー情報を保存しました"
        }
      }
    });
    history.goBack();
  };

  const handleSubmit = useCallback(async () => {
    await API.patchUser(
      userId,
      {
        boxId: Number(boxId) || undefined,
        gender,
        birthday: birthday || undefined
      },
      updateResponse
    ).catch(err => {
      handleException(err);
    });
  }, [birthday, boxId, gender]);

  useEffect(() => {
    setGender(globalState.user.gender);

    setBoxId(`${globalState.user.boxId}` || "");

    if (globalState.user.birthday) {
      const birthday = dayjs(globalState.user.birthday);
      setDefaultInput({
        year: birthday.year().toString(),
        month: (birthday.month() + 1).toString().padStart(2, "0"),
        day: birthday
          .date()
          .toString()
          .padStart(2, "0")
      });
    } else {
      setDefaultInput({
        year: "",
        month: "",
        day: ""
      });
    }
    setShow(true);
  }, []);

  return show ? (
    <>
      <Helmet>
        <title>ユーザー情報</title>
      </Helmet>
      <Header backText="戻る"></Header>
      <Layout>
        <Stack size={5}>
          <FormUserInfo
            defaultInput={defaultInput}
            gender={gender}
            setGender={setGender}
            setDisabled={setDisabled}
            setBirthday={setBirthday}
          ></FormUserInfo>
          <FormBoxId
            ref={boxIdRef}
            boxId={boxId}
            setBoxId={setBoxId}
            setDisabled={setDisabled}
            isPayment
          ></FormBoxId>
          <ButtonPrimary
            type="submit"
            text="登録"
            disabled={disabled || !boxId}
            onClick={() => handleSubmit()}
          ></ButtonPrimary>
        </Stack>
      </Layout>
    </>
  ) : (
    <></>
  );
};

export default ProfilesAccounts;
