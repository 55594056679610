import React, { ReactNode, useEffect } from "react";
import IconClose from "../../assets/images/icon_close.svg";
import s from "./Modal.module.scss";
import Spacer from "./Spacer";

interface Props {
  children: ReactNode;
  close: (value: boolean) => void;
  closeButton?: boolean;
  title?: ReactNode;
}

const Modal = (props: Props): JSX.Element => {
  useEffect(() => {
    const modalEl = document.getElementById("Modal");
    if (modalEl) {
      setTimeout(() => {
        modalEl.classList.remove(s.anime_fadeIn);
      }, 400);
    }
    const modalInnerEl = document.getElementById("ModalInner");
    if (modalInnerEl) {
      modalInnerEl.classList.add(s.anime_fadeIn2);
      setTimeout(() => {
        modalInnerEl.classList.remove(s.anime_fadeIn2);
      }, 400);
    }
  }, []);

  const onClickClose = () => {
    const modalEl = document.getElementById("Modal");
    if (modalEl) {
      modalEl.classList.add(s.anime_fadeOut);
      setTimeout(() => {
        modalEl.classList.remove(s.anime_fadeOut);
        props.close(false);
      }, 300);
    }
  };

  return (
    <div
      id="Modal"
      className={`${s.modal_wrap} ${s.anime_fadeIn}`}
      onClick={(e) => e.target === e.currentTarget && onClickClose()}
    >
      <div id="ModalInner" className={`${s.modal} ${s.anime_fadeIn2}`}>
        <div className={s.header}>
          <div className={s.header_title}>{props.title}</div>
          <div className={s.header_close}>
            {props.closeButton && (
              <img
                src={IconClose}
                alt="とじる"
                onClick={() => onClickClose()}
              />
            )}
          </div>
        </div>
        <Spacer size={2}></Spacer>
        {props.children}
      </div>
    </div>
  );
};

export default Modal;
