import React from "react";
import s from "./Loading.module.scss";

const Loading = (): JSX.Element => {
  return (
    <div className={s.base}>
      <div className={s.icon}>
        <div className={`${s.sp}`}>
          <div className={s.sp_clock}></div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
