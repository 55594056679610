import React from "react";
import Card from "./Card";
import s from "./CardItemMenu.module.scss";
import Divider from "./Divider";
import ItemAllergySel from "./ItemAllergySel";
import ListNutrients from "./ListNutrients";
import Stack from "./Stack";
import TextNutrientsNote from "./TextNutrientsNote";
import dayjs from "dayjs";

export type User = {
  gender: number;
  birthday: string;
};

export interface Nutrient {
  id: string;
  name: string;
  kcal: number | undefined;
  totalKcal: number | undefined;
}

export interface Allergy {
  name: string;
  active: boolean;
}

interface Props {
  user: User | undefined;
  name: string;
  description: string;
  ingredient: string;
  nutrients: Nutrient[];
  allergySels: Allergy[];
}

export const ageOfGeneration = (user: User): number => {
  const today = dayjs();
  return Math.floor(today.diff(dayjs(user.birthday), "year") / 10) * 10;
};

const CardItemMenu = (props: Props): JSX.Element => {
  return (
    <Card>
      <Stack size={4}>
        <div className="gl_text-h1 gl_color-redbrown-400">{props.name}</div>
        <div className="gl_text-sm gl_color-brown-400">{props.description}</div>
        <div className={s.title}>
          <div className={`${s.title_text} gl_text-sm`}>
            ＼あなたにぴったり／
            <br />
            1日分の栄養素に対する割合
          </div>
        </div>
        <ListNutrients nutrients={props.nutrients}></ListNutrients>
        {props.user ? (
          <TextNutrientsNote
            age={ageOfGeneration(props.user)}
            gender={props.user.gender}
          ></TextNutrientsNote>
        ) : null}
        <Divider></Divider>
        <div className="gl_text-sm gl_color-redbrown-400 gl_text-center gl_fontWeight-bold">
          原材料
        </div>
        <div className="gl_text-sm gl_color-brown-400">{props.ingredient}</div>
        <Divider></Divider>
        <div className="gl_text-sm gl_color-redbrown-400 gl_text-center gl_fontWeight-bold">
          アレルギー
        </div>
        <div className={s.grid}>
          {props.allergySels.map((item, i) => (
            <ItemAllergySel
              key={i}
              name={item.name}
              active={item.active}
            ></ItemAllergySel>
          ))}
        </div>
      </Stack>
    </Card>
  );
};

export default CardItemMenu;
