import React from "react";

interface Props {
  text: string;
  id?: string;
}

const Label = ({ text, id }: Props): JSX.Element => {
  return (
    <label htmlFor={id} className="gl_text-sm gl_color-brown-400">
      {text}
    </label>
  );
};

export default Label;
