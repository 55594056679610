import dayjs from "dayjs";
import React from "react";
import Card from "../UI/Card";
import Divider from "../UI/Divider";
import ListItemOrderItem from "../UI/ListItemOrderItem";
import Stack from "../UI/Stack";
import ja from "dayjs/locale/ja";
dayjs.locale(ja);

interface Props {
  createdAt: string;
  // TODO: any型を変更すること
  orderItems: any[];
  coupon?: {
    name: string;
    discount: number;
  };
  totalPrice: number;
  orderId: string;
  paymentMethod: string;
}

const CardOrderHistory = (props: Props): JSX.Element => {
  return (
    <Card>
      <div className="gl_text-right gl_text-sm gl_color-brown-400">
        {dayjs(props.createdAt).format("YYYY年MM月DD日(ddd) HH:mm")}
      </div>
      <Divider margin></Divider>
      <Stack size={4}>
        {props.orderItems.map((item, i) => (
          <ListItemOrderItem
            key={i}
            imgPath={item.imgPath}
            name={item.name}
            price={item.price}
            count={item.count}
          ></ListItemOrderItem>
        ))}
      </Stack>
      <Divider margin></Divider>
      {props.coupon ? (
        <>
          <Stack horizontal justifyContent="space-between" alignItems="center">
            <div className="gl_fontWeight-bold gl_color-brown-400">
              {props.coupon.name}
            </div>
            <div className="gl_text-h3 gl_fontWeight-bold">
              -¥ {props.coupon.discount}
            </div>
          </Stack>
          <Divider margin></Divider>
        </>
      ) : (
        <></>
      )}
      <Stack size={4}>
        <Stack horizontal justifyContent="space-between" alignItems="center">
          <div className="gl_fontWeight-bold gl_color-brown-400">合計</div>
          <div className="gl_text-h2 gl_fontWeight-bold">
            ¥ {props.totalPrice}
          </div>
        </Stack>
        <Stack horizontal justifyContent="space-between" alignItems="center">
          <div className="gl_color-brown-300 gl_text-sm">購入番号</div>
          <div className="gl_color-brown-400 gl_text-sm">{props.orderId}</div>
        </Stack>
        <Stack horizontal justifyContent="space-between" alignItems="center">
          <div className="gl_color-brown-300 gl_text-sm">支払い方法</div>
          <div className="gl_color-brown-400 gl_text-sm">
            {props.paymentMethod}
          </div>
        </Stack>
      </Stack>
    </Card>
  );
};

export default CardOrderHistory;
