import { useEffect, useState } from "react";
import Alert from "src/components/UI/Alert";
import useAppState from "../hooks/useAppState";
import React from "react";
import useAppStateDispatch from "src/hooks/useAppStateDispatch";

const GlobalAlert = (): JSX.Element => {
  const globalState = useAppState();
  const dispatch = useAppStateDispatch();
  const [type, setType] = useState<"success" | "error" | "">("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    setType(globalState.alertMessage.type);
    setMessage(globalState.alertMessage.message);
    console.log(globalState.alertMessage.type);
    console.log(globalState.alertMessage.message)
    if (!globalState.alertMessage.type || !globalState.alertMessage.message) {
      dispatch({
        type: "setShowAlert",
        payload: {
          showAlert: false
        }
      });
    } else {
      dispatch({
        type: "setShowAlert",
        payload: {
          showAlert: true
        }
      });
    }
  }, [globalState.alertMessage]);

  return (
    <>
      {globalState.showAlert && type && message && (
        <Alert type={type} text={message} />
      )}
    </>
  );
};

export default GlobalAlert;
