import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useHandleException } from "src/hooks/useHandleException";
import ApiCaller from "src/libs/ApiWrapper";
import { liff } from "src/libs/liff";

const QrBoxId = (): JSX.Element => {
  // query取得
  const search = useLocation().search;
  const query2 = new URLSearchParams(search);
  const encryptedBoxId = query2.get("id");
  const API = new ApiCaller();
  const history = useHistory();
  const handleException = useHandleException();

  // 友達登録ページへの遷移
  const toAddFriendPage = () => {
    window.location.href = process.env.REACT_APP_LINE_ACCOUNT_URL!;
  };

  useEffect(() => {
    // パラメータにboxIdがない場合
    if (!Number(encryptedBoxId)) {
      localStorage.setItem("encryptedBoxId", "invalid");
      toAddFriendPage();
    }
    localStorage.setItem("encryptedBoxId", encryptedBoxId || "");
    Promise.resolve().then(async () => {
      const resp = await API.decodeBoxId(Number(encryptedBoxId)).catch(err => {
        handleException(err);
      });
      if (resp && resp.isValidDigit) {
        localStorage.setItem("boxId", `${resp.decryptedBoxId}`);
        localStorage.setItem("encryptedBoxId", `${encryptedBoxId}`);
      } else {
        // 不正なboxIdだった場合
        localStorage.setItem("encryptedBoxId", "invalid");
      }
      toAddFriendPage();
    });
  });

  return <></>;
};

export default QrBoxId;
