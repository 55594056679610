import React, { FC } from "react";
import "../../assets/stylesheets/application.scss";
interface ButtonWideProps {
  btnAction: VoidFunction;
  btnText: string;
}
const ButtonWide: FC<ButtonWideProps> = props => {
  const { btnAction, btnText } = props;
  return (
    <div
      className="position-bottom mt-14 w-100 mb-14"
      onClick={(): void => btnAction()}
    >
      <div className="bg-orange-C br-28 box-shadow-x0-y3-b8 opacity-100 h-56px ml-16 mr-16">
        <div className="vertical-center align-text-center w-100 nsj-medium bold cs-white-text text-size-24px">
          {btnText}
        </div>
      </div>
    </div>
  );
};
export default ButtonWide;
