export default {
  plusCircle: require("../assets/images/plus-circle.svg"),
  shoppingCart: require("../assets/images/shopping-cart.svg"),
  trashCan: require("../assets/images/trash-alt.svg"),
  linePay: require("../assets/images/linepay.png"),
  creditCard1: require("../assets/images/icon-creditcard_01.svg"),
  merPay: require("../assets/images/merpay.png"),
  paypay: require("../assets/images/paypay.png"),
  uncheckedCircle: require("../assets/images/unchecked-circle.svg"),
  checkedCircle: require("../assets/images/checked-circle.svg"),
  checkedCircleWhite: require("../assets/images/checked-circle-white.svg"),
  arrowRight: require("../assets/images/angle-right.svg"),
  arrowLeft: require("../assets/images/angle-left.svg"),
  facebookIcon: require("../assets/images/facebook-icon.svg"),
  googleIcon: require("../assets/images/google-icon.svg"),
  envolope: require("../assets/images/envelope.svg"),
  key: require("../assets/images/key.svg"),
  gender: require("../assets/images/transgender-alt.svg"),
  userAlt: require("../assets/images/user-alt.svg"),
  xInCircle: require("../assets/images/x-in-circle.svg"),
  gavel: require("../assets/images/gavel.svg"),
  building: require("../assets/images/building.svg"),
  fileAlt: require("../assets/images/file-alt.svg"),
  userCircle: require("../assets/images/user-circle.svg"),
  userCircleDark: require("../assets/images/user-circle-dark.svg"),
  userShield: require("../assets/images/user-shield.svg"),
  historyBig: require("../assets/images/history-big.svg"),
  history: require("../assets/images/history.svg"),
  link: require("../assets/images/link.svg"),
  birthdayCake: require("../assets/images/birthday-cake.svg"),
  check: require("../assets/images/check.svg"),
  blackStar: require("../assets/images/black-star.svg"),
  triangleLeft: require("../assets/images/triangle-left.svg"),
  triangleRight: require("../assets/images/triangle-right.svg"),
  newIcon: require("../assets/images/newicon.png"),
  hotIcon: require("../assets/images/icon-warm.svg"),
  coldIcon: require("../assets/images/icon-cold.svg"),
  warmingtimeShort: require("../assets/images/tab-warmingtime_01.svg"),
  warmingtimeLong: require("../assets/images/tab-warmingtime_02.svg"),
  profielCreditcard: require("../assets/images/icon-creditcard_02.svg")
};
