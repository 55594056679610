import React, { FC, Fragment, useEffect, useState } from "react";
import { Route, Router, Switch } from "react-router-dom";
import history from "./History";
import queryString from "query-string";
import HTTPClient from "../libs/HTTPClient";

// 新規import
import Registrations from "./pages/Registrations";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Company from "./pages/Company";
import Withdrawal from "./pages/Withdrawal";
import WithdrawalComplete from "./pages/WithdrawalComplete";
import Profiles from "./pages/Profiles";
import ProfilesAccounts from "./pages/ProfilesAccounts";
import ProfilesCoupons from "./pages/ProfilesCoupons";
import CreditCards from "./pages/CreditCards";
import CreditCardsRegistrations from "./pages/CreditCardsRegistrations";
import ItemId from "./pages/ItemId";
import Payments from "./pages/Payments";

// 既存import
import useAppStateDispatch from "../hooks/useAppStateDispatch";

import OrderHistories from "./pages/OrderHistories";
import Home from "./pages/Home";
import PaymentsComplete from "./pages/PaymentsComplete";
import Error from "./pages/Error";
import TermsOfUse from "./pages/TermsOfUse";
import SpecifiedCommercialTransactionAct from "./pages/SpecifiedCommercialTransactionAct";
import ScrollToTop from "src/hooks/ScrollToTop";
import logger from "src/libs/Logger";
import { useLiffInit } from "src/hooks/useLiffInit";
import ApiCaller from "src/libs/ApiWrapper";
import useAppState from "src/hooks/useAppState";
import { GenderType } from "src/libs/types/enums";
import QrBoxId from "./pages/QrBoxId";
import ErrorQr from "./pages/ErrorQr";
import GlobalAlert from "./GlobalAlert";
import { InitRoute } from './InitRoute';

interface DataResponse {
  data: {
    attributes: {
      box: { boxId: number; displayName: string };
      items: {
        description: string;
        id: number;
        itemCategoryId: number;
        itemCategoryName: string;
        mainImg: string;
        name: string;
        order: number;
        price: number;
        thumbImg: string;
      }[];
      itemCategories: {
        categoryImg: string;
        id: number;
        name: string;
        order: number;
      }[];
      favorites: {
        id: number;
        itemId: number;
        userId: number;
      }[];
    };
  };
}

interface UserOptionsResponse {
  status: number;
  statusText: string;
  headers: {
    status: number;
    statusText: string;
  };
  data: {
    error?: string;
    id: number;
    gender: GenderType;
    boxId: number;
    birthday: string;
    encryptedBoxId: number;
  };
}

interface ScreenManagementProps {
  data: DataResponse;
}

const ScreenManagement: FC<ScreenManagementProps> = (
  props: ScreenManagementProps
) => {
  const [mounted, setMounted] = useState(false);
  const { data } = props;
  const API = new ApiCaller();
  const dispatch = useAppStateDispatch();
  const { ready } = useLiffInit();

  useEffect(() => {
    Promise.resolve().then(async () => {
      if (localStorage.getItem("cartData")) {
        const cartData = JSON.parse(localStorage.getItem("cartData") || "[]");
        dispatch({
          type: "setCartAction",
          payload: { cart: cartData }
        });
      }
    });
  }, []);

  if (ready) {
    return (
      <Router history={history}>
        <ScrollToTop />
        <GlobalAlert />
        <Switch>
          {/* 画面 */}
          {/* 1.ユーザー情報登録画面 - /registrations */}
          <Route exact path="/registrations" component={Registrations} />

          {/* 2.商品一覧画面 - / */}
          <InitRoute exact path="/" component={Home} />
          {/* 3.商品詳細画面 - /item/:id */}
          <Route exact path="/item/:id" component={ItemId} />
          {/* <Route exact path="/item/:id" component={ItemScreen} /> */}

          {/* 4.支払い画面（カート） - /payments */}
          {/* <Route exact path="/payments" component={PaymentScreen} /> */}
          <Route exact path="/payments" component={Payments} />

          {/* 5.支払い完了画面 - /payments/complete */}
          <Route exact path="/payments/complete" component={PaymentsComplete} />
          {/* <Route
        exact
        path="/payments/complete"
        render={(props) => (
          <PaymentCompleteScreen
            status={true}
            queryString={queryString.parse(props.location.search)}
          />
        )}
      /> */}
          {/* 支払い完了画面(失敗) - /payments/failure */}
          {/* <Route
        exact
        path="/payments/failure"
        render={(props) => (
          <PaymentCompleteScreen
            status={false}
            queryString={queryString.parse(props.location.search)}
          />
        )}
      /> */}

          {/* 6.ユーザー設定画面 - /profiles/menu */}
          <Route exact path="/profiles/menu" component={Profiles} />
          {/* <Route exact path="/profiles/menu2" component={ProfileMenuScreen} /> */}

          {/* 7.プロフィール変更画面 - /profiles/accounts/edit */}
          <Route
            exact
            path="/profiles/accounts/edit"
            component={ProfilesAccounts}
          />
          {/* <Route
        exact
        path="/profiles/accounts/edit2"
        component={AccountModificationScreen}
      /> */}

          {/* 8.購入履歴画面 - /profiles/order-histories */}
          <Route
            exact
            path="/profiles/order-histories"
            component={OrderHistories}
          />
          {/* <Route exact path="/orders/histories" component={OrderHistoryScreen} /> */}

          {/* 9.所有クーポン画面 - /profiles/coupons */}
          <Route exact path="/profiles/coupons" component={ProfilesCoupons} />

          {/* 10.クレジットカード一覧画面 - /credit_cards */}
          <Route exact path="/credit_cards" component={CreditCards} />

          {/* 11.クレジットカード登録画面 - /credit_cards/registrations */}
          <Route
            exact
            path="/credit_cards/registrations"
            component={CreditCardsRegistrations}
          />

          {/* 12.利用規約 - /terms_of_use/email */}
          <Route
            exact
            path="/terms_of_use/email"
            render={() => <TermsOfUse />}
          />
          {/* <Route
        exact
        path="/terms_of_use/email"
        render={() => <TermsOfUse returnLocation="" />}
      /> */}

          {/* 13.プライバシーポリシー - /privacy_policy/profile */}
          <Route
            exact
            path="/privacy_policy/profile"
            render={() => <PrivacyPolicy />}
          />

          {/* 14.特定商取引法に基づく表示 - /specified_commercial_transaction_act */}
          <Route
            exact
            path="/specified_commercial_transaction_act"
            render={() => <SpecifiedCommercialTransactionAct />}
          />
          {/* <Route
        exact
        path="/specified_commercial_transaction_act"
        render={() => (
          <SpecifiedCommercialTransactionActScreen returnLocation="" />
        )}
      /> */}

          {/* 15.運営会社 - /company */}
          <Route exact path="/company" render={() => <Company />} />

          {/* 16.共通エラーページ - /error */}
          <Route
            exact
            path="/error"
            render={() => <Error type="serviceUnavailable" />}
          />
          {/* <Route
        exact
        path="/error"
        render={(props) => (
          <GenericErrorScreen
            queryString={queryString.parse(props.location.search)}
          />
        )}
      /> */}

          {/* 17.退会 - /withdrawal */}
          <Route exact path="/withdrawal" render={() => <Withdrawal />} />

          {/* 18.退会完了 - /withdrawal/complete */}
          <Route
            exact
            path="/withdrawal/complete"
            render={() => <WithdrawalComplete />}
          />

          {/* 19.システムメンテナンス - /system_maintenance */}
          <Route
            exact
            path="/system_maintenance"
            render={() => <Error type="maintenance" />}
          />
          {/* 20.QRコード読み取りエラー画面 - /error-qr */}
          <Route exact path="/error-qr" render={() => <ErrorQr />} />
          {/* 冷蔵庫ID取得QRコード画面 - /users/sign_up */}
          <Route exact path="/qr/box_id" component={QrBoxId} />
          {/* 19.サーバーエラー - /server_error */}
          <Route
            exact
            path="/server_error"
            render={() => <Error type="serverError" />}
          />
          {/* 19.サービス利用不可 - /service_unavailable */}
          <Route
            exact
            path="/service_unavailable"
            render={() => <Error type="serviceUnavailable" />}
          />
          {/*404ページ*/}
          <Route path="*">
            <Error type="notFound" />
          </Route>
        </Switch>
      </Router>
    );
  } else {
    return <></>;
  }
};
export default ScreenManagement;
