import React from "react";
import s from "./Header.module.scss";
import IconAngleLeft from "../../assets/images/icon_angle-left.svg";
import IconAccount from "../../assets/images/icon_account.svg";
import { useHistory } from "react-router-dom";

interface Props {
  backText: string;
  account?: boolean;
}

const Header = (props: Props): JSX.Element => {
  const history = useHistory();
  return (
    <div className={s.header}>
      <div className={s.header_inner} onClick={() => history.goBack()}>
        <img src={IconAngleLeft} alt="戻る" />
        <span className={s.header_text}>{props.backText}</span>
      </div>
      {props.account && (
        <img
          src={IconAccount}
          alt="ユーザー"
          width={28}
          height={28}
          onClick={() => {
            history.push("/profiles/menu");
          }}
        />
      )}
    </div>
  );
};

export default Header;
