import React from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import ImgAboutBoxId from "../../assets/new-images/img-about-box-id.svg";
import ButtonPrimary from "../UI/ButtonPrimary";
import Layout from "../UI/Layout";
import Spacer from "../UI/Spacer";
import s from "./ErrorQr.module.scss";

const ErrorQr = (): JSX.Element => {
  const history = useHistory();

  const toAddFriendPage = () => {
    window.location.href = process.env.LINE_ACCOUNT_URL!;
  };

  return (
    <>
      <Helmet>
        <title>エラー</title>
      </Helmet>
      <Layout noFooter>
        <Spacer size={10}></Spacer>
        <div className="gl_text-h1 gl_text-center gl_whitespace-prewrap">
          {`読み取ったQRコードは\n「冷蔵庫ID」ではありません`}
        </div>
        <Spacer size={8}></Spacer>
        <div className={s.card}>
          <div className="gl_text-h2 gl_text-center">{`冷蔵庫IDとは`}</div>
          <Spacer size={2.5}></Spacer>
          <img src={ImgAboutBoxId} alt="about" />
        </div>
        <Spacer size={8}></Spacer>
        <ButtonPrimary
          text="LINE公式アカウントへ"
          onClick={() => toAddFriendPage()}
        ></ButtonPrimary>
      </Layout>
    </>
  );
};

export default ErrorQr;
