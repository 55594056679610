import React, { useEffect, useState } from "react";
import CardCoupon from "../UI/CardCoupon";
import Header from "../UI/Header";
import Layout from "../UI/Layout";
import Stack from "../UI/Stack";
import { Helmet } from "react-helmet";
import ButtonPrimary from "../UI/ButtonPrimary";
import Spacer from "../UI/Spacer";
import { useHistory } from "react-router-dom";
import ApiCaller, { Coupon, UserCouponsResponse } from "src/libs/ApiWrapper";
import { useHandleException } from "src/hooks/useHandleException";

// 所有クーポン画面 - /profiles/coupons
const ProfilesCoupons = (): JSX.Element => {
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [show, setShow] = useState(false);
  const history = useHistory();
  const API = new ApiCaller();
  const handleException = useHandleException();

  const userCoupons = (resp: UserCouponsResponse) => {
    setCoupons(resp.coupons);
  };

  useEffect(() => {
    Promise.resolve().then(async () => {
      API.getUserCoupons(userCoupons)
        .then(() => {
          setShow(true);
        })
        .catch(err => {
          handleException(err);
        });
    });
  }, []);

  return show ? (
    <>
      <Helmet>
        <title>所有クーポン</title>
      </Helmet>
      <Header backText="戻る"></Header>
      <Layout>
        {coupons.length ? (
          <Stack size={5}>
            {coupons.map((coupon, i) => (
              <CardCoupon
                name={coupon.name.replace(/\r?\n/g, "")}
                effect={coupon.effect}
                effectValue1={coupon.effectValue1}
                effectValue2={coupon.effectValue2}
                expiresAt={coupon.expiresAt}
                termsOfUse={coupon.termsOfUse}
                termsOfUseValue={coupon.termsOfUseValue}
                note={coupon.note}
              ></CardCoupon>
            ))}
          </Stack>
        ) : (
          <>
            <Spacer size={5}></Spacer>
            <Stack size={5} alignItems="center">
              <img
                src={require("../../assets/new-images/icon-xl-coupon.svg")}
                alt=""
                width={84}
              />
              <span className="gl_color-brown-400">
                所有クーポンはありません
              </span>
              <ButtonPrimary
                size="md"
                text="戻る"
                color="cancel"
                outlined
                onClick={() => history.goBack()}
              ></ButtonPrimary>
            </Stack>
          </>
        )}
      </Layout>
    </>
  ) : (
    <></>
  );
};

export default ProfilesCoupons;
