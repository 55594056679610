import React from "react";
import s from "./TextLink.module.scss";
import { Link } from "react-router-dom";

interface Props {
  text: string;
  onClick?: () => void;
  to?: string;
  size?: "xs" | "sm";
}

const TextLink = (props: Props): JSX.Element => {
  return (
    <>
      {props.to && (
        <Link
          to={props.to}
          className={`${s.link} ${
            props.size ? `gl_text-${props.size}` : "gl_text-sm"
          }`}
        >
          {props.text}
        </Link>
      )}
      {!props.to && (
        <span
          className={`${s.link} ${
            props.size ? `gl_text-${props.size}` : "gl_text-sm"
          }`}
          onClick={() => props.onClick && props.onClick()}
        >
          {props.text}
        </span>
      )}
    </>
  );
};

export default TextLink;
