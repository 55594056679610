import React, { useState } from "react";
import useAppStateDispatch from "src/hooks/useAppStateDispatch";
import { useHandleException } from "src/hooks/useHandleException";
import ApiCaller, { CardInfo } from "src/libs/ApiWrapper";
import { CardBrandType } from "src/libs/types/enums";
import ButtonIconCircle from "./ButtonIconCircle";
import ImgCardBrand from "./ImgCardBrand";
import s from "./ListItemCreditCard.module.scss";
import Modal from "./Modal";
import ModalInnerWarning from "./ModalInnerWarning";
import Stack from "./Stack";

interface Props {
  id: string;
  brand: CardBrandType;
  expYear: string;
  expMonth: string;
  number: string;
  checkActive?: boolean;
  onClickCheck?: () => void;
  setCards?: (val: CardInfo[]) => void;
  setShowLoading?: (val: boolean) => void;
}

const ListItemCreditCard = (props: Props): JSX.Element => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const API = new ApiCaller();
  const dispatch = useAppStateDispatch();
  const handleException = useHandleException();

  const deleteCardResponse = async () => {
    Promise.resolve().then(async () => {
      if (props.setCards) {
        const cards = await API.getCards().catch(err => {
          handleException(err);
          return [];
        });
        props.setCards(cards);
      }
      if (props.setShowLoading) {
        props.setShowLoading(false);
      }
      dispatch({
        type: "setAlertMessage",
        payload: {
          alertMessage: {
            type: "success",
            message: "クレジットカードを削除しました"
          }
        }
      });
    });
  };

  const deleteCard = async () => {
    setShowDeleteModal(false);
    if (props.setShowLoading) {
      props.setShowLoading(true);
    }
    await API.deleteCard(props.id, deleteCardResponse).catch(err => {
      handleException(err);
    });
  };

  return (
    <>
      <div key={props.id} className={s.item}>
        <Stack horizontal justifyContent="space-between" alignItems="center">
          {/* left */}
          <Stack horizontal alignItems="center" size={2}>
            {/* img */}
            <ImgCardBrand brand={props.brand}></ImgCardBrand>
            {/* cardInfo */}
            <div>
              <div className="gl_text-sm gl_fontWeight-bold">
                ●●●● ●●●● ●●●● ●{props.number.slice(-3)}
              </div>
              <div className="gl_text-sm gl_color-brown-300 gl_fontWeight-bold">
                有効期限 {props.expYear}/{props.expMonth}
              </div>
            </div>
          </Stack>
          {/* right */}
          {props.onClickCheck ? (
            <ButtonIconCircle
              icon={props.checkActive ? "check" : "none"}
              active={props.checkActive}
              onClick={() => props.onClickCheck && props.onClickCheck()}
            ></ButtonIconCircle>
          ) : (
            <button onClick={() => setShowDeleteModal(true)}>
              <img
                src={require("../../assets/new-images/icon-sm-close.svg")}
                alt="削除"
              />
            </button>
          )}
        </Stack>
      </div>
      {showDeleteModal && (
        <Modal close={setShowDeleteModal}>
          <ModalInnerWarning
            title={`このクレジットカードを\n削除してよろしいですか？`}
            submitButtonText="削除"
            onClickSubmit={() => deleteCard()}
            onClickCancel={() => setShowDeleteModal(false)}
          ></ModalInnerWarning>
        </Modal>
      )}
    </>
  );
};

export default ListItemCreditCard;
