import React from "react";
import { useHistory } from "react-router-dom";
import ButtonPrimary from "../UI/ButtonPrimary";
import Layout from "../UI/Layout";
import Spacer from "../UI/Spacer";
import { Helmet } from "react-helmet";

interface Props {
  type?: "maintenance" | "notFound" | "serverError" | "serviceUnavailable";
}

const Error = (props: Props): JSX.Element => {
  const history = useHistory();

  let title = `お探しのページは\n見つかりませんでした`;
  let text = `あなたがお探しのページは消去されたか\nURLが変更されているため、\n見つけることができませんでした。`;
  if (props.type === "maintenance") {
    title = `ただいま\nメンテナンス中です`;
    text = `テキストテキストテキストテキストテキスト\nテキストテキストテキストテキスト\nテキストテキストテキストテキストテキスト`;
  }
  if (props.type === "notFound") {
    title = `お探しのページは\n見つかりませんでした`;
    text = `あなたがお探しのページは消去されたか\nURLが変更されているため、\n見つけることができませんでした。`;
  }
  if (props.type === "serverError") {
    title = `お探しのページは\n表示できませんでした`;
    text = `サーバーエラーが発生しました。\n再度時間をおいてアクセスしてください。`;
  }
  if (props.type === "serviceUnavailable") {
    title = `混雑のため一時的に\nアクセスができません`;
    text = `一時的にアクセスできません。\n混雑のためアクセスしにくくなっています。\n再度時間をおいてアクセスしてください。`;
  }

  return (
    <>
      <Helmet>
        <title>エラー</title>
      </Helmet>
      <Layout noFooter>
        <Spacer size={7}></Spacer>
        <div className="gl_text-center">
          <img
            src={require("../../assets/new-images/icon-xl-search.svg")}
            alt="notfound"
          />
        </div>
        <Spacer size={6}></Spacer>
        <div className="gl_text-h1 gl_text-center gl_whitespace-prewrap">
          {title}
        </div>
        <Spacer size={4}></Spacer>
        <div className="gl_text-center gl_whitespace-prewrap gl_color-brown-400">
          {text}
        </div>
        <Spacer size={10}></Spacer>
        <ButtonPrimary
          text="トップページへ戻る"
          onClick={() => history.push("/")}
        ></ButtonPrimary>
      </Layout>
    </>
  );
};

export default Error;
