import React, { FC, useEffect, useState } from "react";
import { useHandleException } from "src/hooks/useHandleException";
import useAppState from "src/hooks/useAppState";
import ApiCaller, { CardInfo } from "src/libs/ApiWrapper";
import useAppStateDispatch from "src/hooks/useAppStateDispatch";

interface Props {
  setCards: (val: CardInfo[]) => void;
  setCardToken?: (val: string) => void;
  cart?: boolean;
  setShowLoading?: (val: boolean) => void;
  setCardId?: (val: string) => void;
}

const FormCards = (props: Props): JSX.Element => {
  const [cardInfo, setCardInfo] = useState<CardInfo | null>();
  const API = new ApiCaller();
  const handleException = useHandleException();
  const globalState = useAppState();
  const dispatch = useAppStateDispatch();
  /**
   * payjp側が作成するifame側でsubmitされた時の処理用scriptタグ追加
   */
  const createSubmitScript = (form: HTMLFormElement): void => {
    const submitScript = document.createElement("script");
    submitScript.type = "text/javascript";
    submitScript.text = `
    function submitForm(e) {
      var cardTokenEl = document.getElementById("card-token");
      cardTokenEl.value = e.id;
    }
    `;
    form.appendChild(submitScript);
  };

  /**
   * payjp側のcheckout用scriptタグ追加
   */
  const createCheckoutScript = (form: HTMLFormElement): void => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://checkout.pay.jp/";
    script.className = "payjp-button";
    script.setAttribute(
      "data-key",
      process.env.REACT_APP_PAY_JP_PUBLIC_KEY || ""
    );
    script.setAttribute("data-lang", "ja");
    script.setAttribute("data-text", "新しいカードを追加");
    script.setAttribute("data-partial", "true");
    script.setAttribute("data-on-created", "submitForm");
    script.setAttribute("data-submit-text", "カード登録");
    form?.appendChild(script);
  };

  /**
   * cardtokenの変更検知用のobserverを追加
   */
  const addCardTokenObserver = (): MutationObserver => {
    const cardTokenEl = document.getElementById(
      "card-token"
    ) as HTMLInputElement;
    const observer = new MutationObserver(async m => {
      if (props.setShowLoading) {
        props.setShowLoading(true);
      }
      if (props.cart) {
        if (props.setCardToken) {
          props.setCardToken(cardTokenEl.value);
        }
        return;
      }
      const user = globalState.user;
      const cardId = await API.postCreditCard(
        { cardToken: cardTokenEl.value || "" },
        user.id
      ).catch(err => {
        handleException(err);
      });
      if (!cardId) {
        dispatch({
          type: "setAlertMessage",
          payload: {
            alertMessage: {
              type: "error",
              message: "クレジットカードの登録に失敗しました"
            }
          }
        });
        if (props.setShowLoading) {
          props.setShowLoading(false);
        }
        return;
      }
      const cards = await API.getCards().catch(err => {
        handleException(err);
        return [];
      });
      props.setCards(cards);
      if (props.setCardId) {
        props.setCardId(cards[0].id);
      }
      if (props.setShowLoading) {
        props.setShowLoading(false);
      }
      dispatch({
        type: "setAlertMessage",
        payload: {
          alertMessage: {
            type: "success",
            message: "クレジットカードを登録しました"
          }
        }
      });
    });
    observer.observe(cardTokenEl, { attributes: true });
    return observer;
  };

  useEffect(() => {
    const getCardInfo = async (userId: number): Promise<void> => {
      const cardInfo: CardInfo | null = await new ApiCaller().getCardInfo(
        userId
      );
      setCardInfo(cardInfo);
    };
    const user = globalState.user;
    if (user.id) {
      getCardInfo(user.id);
    }
    const form = document.getElementById("payjpForm") as HTMLFormElement;
    if (form) {
      createSubmitScript(form);
      const observer = addCardTokenObserver();
      createCheckoutScript(form);
      return (): void => {
        // body直下にクレカ入力のiframeが書き出されてしまうので遷移時に削除
        const iframe = document.getElementById("payjp-checkout-iframe");
        iframe?.parentElement?.remove();
        (window as any).PayjpCheckout = null; //windowのグローバル型の定義方法がこのプロジェクトで明確ではないので一旦anyで実装してます
        document.body.removeAttribute("style");
        observer.disconnect();
      };
    }
  }, []);

  return (
    <>
      <div
        className="border-grey-bottom-1 grid-row-12 pb-12 mt-13"
        style={{ textAlign: "center" }}
      >
        <form id="payjpForm">
          <input type="hidden" id="card-token" />
        </form>
      </div>
    </>
  );
};

export default FormCards;
