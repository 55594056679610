import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ButtonPrimary from "../UI/ButtonPrimary";
import Card from "../UI/Card";
import CardWarning from "../UI/CardWarning";
import Header from "../UI/Header";
import Layout from "../UI/Layout";
import Stack from "../UI/Stack";
import { Helmet } from "react-helmet";
import ApiCaller from "src/libs/ApiWrapper";
import useAppStateDispatch from "src/hooks/useAppStateDispatch";
import useAppState from "src/hooks/useAppState";
import { System503Error, System5xxError } from "src/types/libs/Errors";
import { useHandleException } from "src/hooks/useHandleException";

const Withdrawal = (): JSX.Element => {
  const history = useHistory();
  const API = new ApiCaller();
  const globalState = useAppState();
  const handleException = useHandleException();
  const dispatch = useAppStateDispatch();

  const clearLocalStorage = (resp: any) => {
    localStorage.removeItem("boxId");
    localStorage.removeItem("encryptedBoxId")
    dispatch({
      type: "clearCartAction"
    })
    history.push("/withdrawal/complete");
  };

  const onSubmit = () => {
    Promise.resolve().then(async () => {
      API.deleteUser(globalState.user.id, clearLocalStorage).catch(err => {
        handleException(err);
      });
    });
  };
  return (
    <>
      <Helmet>
        <title>退会手続き</title>
      </Helmet>
      <Header backText="戻る"></Header>
      <Layout>
        <Stack size={5}>
          <Card>
            <Stack size={4}>
              <div className="gl_text-h1 gl_text-center">
                本当に退会して
                <br />
                よろしいですか？
              </div>
              <div>以下、注意事項をご確認の上、退会にお進みください</div>
              <CardWarning
                text={`退会すると、ユーザー情報が削除され、購入履歴を閲覧することはできません`}
              ></CardWarning>
              <CardWarning
                text={`LINEのお友だちを再登録しても、過去のデータは引き継がれません`}
              ></CardWarning>
            </Stack>
          </Card>
          <div className="gl_text-center">
            <ButtonPrimary
              text="キャンセル"
              color="cancel"
              size="md"
              outlined
              onClick={() => history.goBack()}
            ></ButtonPrimary>
          </div>
          <div className="gl_text-center">
            <ButtonPrimary
              text="退会"
              color="danger"
              size="md"
              onClick={() => onSubmit()}
            ></ButtonPrimary>
          </div>
        </Stack>
      </Layout>
    </>
  );
};

export default Withdrawal;
