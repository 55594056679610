import React from "react";
import s from "./ButtonIconCircle.module.scss";

interface Props {
  icon: "minus" | "plus" | "check" | "none";
  active?: boolean;
  onClick: () => void;
}

const ButtonIconCircle = (props: Props): JSX.Element => {
  return (
    <div
      className={`${s.base} ${props.active && s.active}`}
      onClick={() => props.onClick()}
    >
      <Icon
        color={props.active ? "#fc6c26" : "#cfc8c6"}
        icon={props.icon}
      ></Icon>
    </div>
  );
};

interface IconProps {
  color: string;
  icon: "minus" | "plus" | "check" | "none";
}

const Icon = (props: IconProps): JSX.Element => {
  if (props.icon === "minus") {
    return <IconMinus color={props.color}></IconMinus>;
  }
  if (props.icon === "plus") {
    return <IconPlus color={props.color}></IconPlus>;
  }
  if (props.icon === "check") {
    return <IconCheck color={props.color}></IconCheck>;
  }
  return <></>;
};

interface SvgProps {
  color: string;
}

const IconMinus = ({ color }: SvgProps): JSX.Element => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_731_11828"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="12"
        height="12"
      >
        <rect x="8" y="8" width="12" height="12" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_731_11828)">
        <path
          d="M19.2708 15H8.72962C8.53611 15 8.35053 14.8946 8.2137 14.7071C8.07687 14.5196 8 14.2652 8 14C8 13.7348 8.07687 13.4804 8.2137 13.2929C8.35053 13.1054 8.53611 13 8.72962 13H19.2708C19.4643 13 19.6498 13.1054 19.7867 13.2929C19.9235 13.4804 20.0004 13.7348 20.0004 14C20.0004 14.2652 19.9235 14.5196 19.7867 14.7071C19.6498 14.8946 19.4643 15 19.2708 15Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

const IconPlus = ({ color }: SvgProps): JSX.Element => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_731_11774"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="12"
        height="12"
      >
        <rect x="8" y="8" width="12" height="12" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_731_11774)">
        <path
          d="M19.2708 15H8.72962C8.53611 15 8.35053 14.8946 8.2137 14.7071C8.07687 14.5196 8 14.2652 8 14C8 13.7348 8.07687 13.4804 8.2137 13.2929C8.35053 13.1054 8.53611 13 8.72962 13H19.2708C19.4643 13 19.6498 13.1054 19.7867 13.2929C19.9235 13.4804 20.0004 13.7348 20.0004 14C20.0004 14.2652 19.9235 14.5196 19.7867 14.7071C19.6498 14.8946 19.4643 15 19.2708 15Z"
          fill={color}
        />
        <path
          d="M13 19.2704L13 8.72959C13 8.53609 13.1054 8.35052 13.2929 8.21369C13.4804 8.07687 13.7348 8 14 8C14.2652 8 14.5196 8.07687 14.7071 8.21369C14.8946 8.35052 15 8.53609 15 8.72959L15 19.2704C15 19.4639 14.8946 19.6495 14.7071 19.7863C14.5196 19.9231 14.2652 20 14 20C13.7348 20 13.4804 19.9231 13.2929 19.7863C13.1054 19.6495 13 19.4639 13 19.2704Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

const IconCheck = ({ color }: SvgProps): JSX.Element => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_746_11667"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="12"
        height="12"
      >
        <rect x="8" y="8" width="12" height="12" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_746_11667)">
        <path
          d="M9 13.5L12.8095 17L19 10"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default ButtonIconCircle;
